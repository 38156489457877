import React from 'react';
import { Tooltip, Typography, Space } from 'antd';
import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';

/**
 * @description This is a ProTable which is nested under each leads row, This displays state of each steps in details.
 * @param {Row Record} record
 * @returns {JSX.Element} Table Nested in Leads Records
 */

const expandedRowRender = (record) => {
  const html = record.description;
  return (
    <Space style={{ padding: 10 }}>
      <Typography.Text>{ReactHtmlParser(html)}</Typography.Text>
    </Space>
  );
};

const expandIcon = ({ expanded, onExpand, record }) => (
  <Tooltip title="See Description">
    {expanded ? (
      <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
    ) : (
      <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
    )}
  </Tooltip>
);

export { expandedRowRender as default, expandIcon };
