import React from 'react';
import routes from '@config/routes';

/**
 * @description used to define Minor Layout Settings, Refer API for more options
 * @link https://procomponents.ant.design/en-US/components/layout#api
 */
const defaultSettings = {
  ...routes,
  locale: 'en-US',
  navTheme: 'dark',
  layout: 'side',
  contentWidth: 'Fluid',
  fixedHeader: true,
  fixSiderbar: true,
  colorWeak: false,
  pwa: false,
  title: 'Engagement Hero',
  siderWidth: 160,
  contentStyle: contentStyle(),
  logo,
};

/**
 * Define any lengthy rule as a function below to unclutter config object
 */

// ContentStyles
function contentStyle() {
  return { minHeight: 'calc(100vh - 100px)' };
}

// Logo
function logo() {
  return (
    <img
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        imageRendering: 'auto',
      }}
      src="./logo.png"
      alt="logo"
    />
  );
}

export default defaultSettings;
