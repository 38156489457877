/* eslint-disable prettier/prettier */
import { Button, message, notification, Space } from 'antd';
import { useHistory } from 'react-router';
import ProTable from '@ant-design/pro-table';
import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer } from '@ant-design/pro-layout';
import { collection, onSnapshot, query, where } from '@firebase/firestore';
import isEmpty from 'lodash/isEmpty';

import {
  setClient,
  // searchClient,
  // clearSearch,
  setLoading,
  setEditOrAddUser,
  // deleteClient,
} from '@pages/users/slice';
import { db } from '@services/firebase';
import useTabKey from '@hooks/useTabKey';
import { bulkUpdate } from '@utils/firebase';
import columns from '@pages/users/config/column';
import tabList from '@pages/users/config/tabList';
import AddUserModal from '@pages/users/components/AddUser';

const User = () => {
  const history = useHistory();
  const ref = useRef();
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isDoingBulkOperation, setIsDoingBulkOperation] = useState(false);
  const [searchString, setSearchString] = useState('');
  const bulkActionEnabled = useMemo(() => selectedRowKeys.length === 0, [selectedRowKeys]);
  const { client, filterClient, searching, loading, editOrAddUser } = useSelector(
    (state) => state.client
  );
  const activeTabKey = useTabKey(
    useCallback(() => {
      setSelectedRowKeys([]);
    }, [])
  );

  const { selectedUser, openAddUser } = editOrAddUser;
  const toggleEditUser = (show, user = {}) => {
    dispatch(setEditOrAddUser({ openAddUser: show, selectedUser: user }));
  };
  const showBulkOperation = searching ? filterClient.length > 0 : client.length > 0;
  const handleBulk = async (updateKey, type) => {
    try {
      setIsDoingBulkOperation(type);
      await bulkUpdate({
        searchValues: selectedRowKeys,
        searchIn: 'users',
        searchField: 'id',
        replaceWith: { [updateKey]: activeTabKey !== 'trash' },
        operationType: type,
      });
      // batch(() => {
      //   selectedRowKeys.forEach((key) => dispatch(deleteClient(key)));
      // });
    } catch (error) {
      notification.error({ message: 'Error occurred', description: 'Operation failed try again' });
    } finally {
      setIsDoingBulkOperation(false);
      setSelectedRowKeys([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const changeTab = (key) => {
    history.push(`${history.location.pathname}?type=${key}`);
    ref.current.resetFields();
  };

  const searchObject = useMemo(() => {
    const pairs = history.location.search.slice(1).split('&');
    const result = {};
    pairs.forEach((pair) => {
      pair = pair.split('=');
      if (pair.length) {
        result[pair[0]] = decodeURIComponent(pair[1] || '');
        result[pair[0]] = decodeURIComponent(pair[1]?.replace(/\+/g, '%20') || '');
      }
    });
    return JSON.parse(JSON.stringify(result));
  }, [history.location.search]);

  useEffect(() => {
    if (ref.current.setFieldsValue) {
      ref.current.setFieldsValue({
        ...searchObject,
      });
    }
  }, [searchObject, ref]);

  const filteredUser = useMemo(() => {
    let isEquivalent = false;
    const filterClientData = client.filter((clientData) => {
      let searchParams = Object.keys(searchObject);
      searchParams = searchParams.filter((item) => item !== 'type');
      for (let i = 0; i < searchParams.length; i += 1) {
        isEquivalent = false;
        const pattern = `${searchObject[searchParams[i]]}`.toLowerCase();
        const regex = new RegExp(pattern);
        if (searchParams[i] === 'userId') {
          if (regex.test(clientData.id.toLowerCase())) {
            isEquivalent = true;
            break;
          }
        } else if (regex.test(`${clientData[searchParams[i]]}`.toLowerCase())) {
          isEquivalent = true;
          break;
        }
      }
      if (isEquivalent && searchString) {
        const searchData = JSON.stringify(clientData)
          .toLowerCase()
          .includes(searchString.toLowerCase());
        if (searchData) {
          isEquivalent = true;
        } else {
          isEquivalent = false;
        }
      }
      if (!searchParams.length) {
        isEquivalent = true;
      }
      return isEquivalent;
    });
    return filterClientData;
  }, [searchObject, client, searchString]);

  const getUsers = useCallback(() => {
    dispatch(setLoading(true));
    let q = query(collection(db, 'users'));
    const queryBuilder = (isAdmin, isDeleted = false) => [
      collection(db, 'users'),
      where('isAdmin', '==', isAdmin),
      where('isDeleted', '==', isDeleted),
    ];
    if (activeTabKey === 'users') {
      q = query(...queryBuilder(false));
    } else if (activeTabKey === 'admins') {
      q = query(...queryBuilder(true));
    } else if (activeTabKey === 'trash') {
      q = query(collection(db, 'users'), where('isDeleted', '==', true));
    }

    return onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.docs && querySnapshot.docs.length) {
        const dataNew = querySnapshot.docs.map((docSnapshot) => ({
          ...docSnapshot.data(),
          key: docSnapshot.id,
        }));
        dispatch(setClient(dataNew));
      } else {
        dispatch(setClient([]));
      }
    });
  }, [activeTabKey, dispatch]);

  useEffect(() => {
    let unsubscribe = null;
    unsubscribe = getUsers();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [getUsers]);

  return (
    <PageContainer
      tabList={tabList}
      onTabChange={changeTab}
      tabActiveKey={activeTabKey}
      extra={[
        <Button key="1" type="primary" onClick={() => toggleEditUser(true)}>
          Add User
        </Button>,
      ]}
    >
      <AddUserModal
        drawerProps={{
          toggleEditUser,
          selectedUser,
          openAddUser,
        }}
      />
      <ProTable
        formRef={ref}
        options={{ reload: false, density: false }}
        search={{ layout: 'vertical' }}
        scroll={{ y: '60vh' }}
        dataSource={filteredUser}
        columns={columns(toggleEditUser, activeTabKey)}
        rowKey={(record) => record.id}
        headerTitle="Clients"
        size="small"
        rowSelection={rowSelection}
        onSubmit={(c) => {
          if (!isEmpty(c)) {
            const searchParams = new URLSearchParams('');
            if (searchObject?.type) {
              searchParams.set('type', searchObject?.type);
            }
            Object.keys(c).forEach((item) => {
              if (c[item]) {
                searchParams.set(item, c[item]);
              }
            });
            history.replace({
              pathname: history.location.pathname,
              search: searchParams.toString(),
            });
          } else {
            message.info('Please provide value to query');
          }
        }}
        onReset={() => {
          const searchParams = new URLSearchParams('');
          if (searchObject?.type) {
            searchParams.set('type', searchObject?.type);
          }
          history.replace({
            pathname: history.location.pathname,
            search: searchParams.toString(),
          });
          ref.current.resetFields();
        }}
        loading={loading}
        pagination={{
          total: filteredUser.length,
          showLessItems: true,
          responsive: true,
          pageSize: 10,
        }}
        toolbar={{
          search: {
            placeholder: 'Search',
            allowClear: true,
            onSearch: (val) => {
              setSearchString(val);
            },
          },
        }}
        footer={() =>
          showBulkOperation ? (
            <Space className="bulk-action-container">
              <Button
                danger
                type="primary"
                icon={activeTabKey !== 'trash' ? <DeleteOutlined /> : <RetweetOutlined />}
                disabled={bulkActionEnabled}
                loading={isDoingBulkOperation === 'Deleted'}
                onClick={() =>
                  handleBulk('isDeleted', activeTabKey !== 'trash' ? 'Deleted' : 'Restored')
                }
              >
                {activeTabKey !== 'trash' ? 'Delete' : 'Restore'}
              </Button>
            </Space>
          ) : null
        }
      />
    </PageContainer>
  );
};

export default User;
