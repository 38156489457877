import React, { useEffect, useState, useMemo } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import { doc, setDoc, getDoc, updateDoc } from '@firebase/firestore';
import { Card, Form, Input, Button, Tooltip, message } from 'antd';
import { SaveOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { db } from '@services/firebase';
import Loading from '@components/Loading';
import '@pages/settings/index.less';

function Settings() {
  const [formOne] = Form.useForm();
  const [formTwo] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const defaultTransition = useMemo(
    () => ({
      step_one: 24,
      step_two: 24,
      step_three: 24,
      step_four: 24,
      step_five: 24,
      step_six: 24,
    }),
    []
  );

  const onFinishArchive = async (values) => {
    try {
      const archiveTime = Math.floor(values.archive_time) * 24;
      await setDoc(doc(db, 'config', `archive`), { archive_time: archiveTime });
      message.success('Successfully updated archive time');
    } catch (err) {
      message.error('Error setting archive time');
    }
  };

  const onFinishTransition = async (values) => {
    try {
      const transformedValues = Object.entries(values).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: Number(+value) }),
        {}
      );
      await updateDoc(doc(db, 'config', `transition`), transformedValues);
      message.success('Successfully updated transition time');
    } catch (err) {
      message.error('Error setting transition time');
    }
  };

  useEffect(() => {
    async function init() {
      setIsLoading(true);
      const archiveRef = doc(db, 'config', 'archive');
      const transitionRef = doc(db, 'config', 'transition');
      const archive = await getDoc(archiveRef);
      const transition = await getDoc(transitionRef);
      if (archive.exists()) {
        const archiveVals = archive.data();
        formOne.setFieldsValue({ archive_time: Math.floor(archiveVals.archive_time / 24) });
      } else {
        formOne.setFieldsValue({ archive_time: 10 });
        await setDoc(doc(db, 'config', `archive`), { archive_time: 10 });
      }
      if (transition.exists()) {
        const transitionVals = transition.data();
        formTwo.setFieldsValue(transitionVals);
      } else {
        formTwo.setFieldsValue(defaultTransition);
        await setDoc(doc(db, 'config', `transition`), defaultTransition);
      }
      setIsLoading(false);
    }
    init();
  }, [defaultTransition, formOne, formTwo]);

  return (
    <PageContainer>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Form form={formOne} layout="vertical" className="mb-10" onFinish={onFinishArchive}>
            <Card
              className="card-container"
              title="Set Archive Time"
              extra={
                <Tooltip title="This time is used to set default value of time to be used to archive leads.">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            >
              <Form.Item
                label="Archive Time (in days)"
                name="archive_time"
                rules={[
                  {
                    required: true,
                    message: 'Please select archive time!',
                  },
                ]}
                initialValue={0}
              >
                <Input type="number" placeholder="Enter number of days" />
              </Form.Item>
              <Form.Item className="submit-archive-time-btn">
                <Button
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </Form.Item>
            </Card>
          </Form>
          <Form form={formTwo} layout="vertical" onFinish={onFinishTransition}>
            <Card
              className="card-container"
              title="Set Transition Time"
              extra={
                <Tooltip title="This time is used to set default value of each step Transition Time.">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            >
              <Form.Item
                label="Step 1 Transition Time (in hours)"
                name="step_one"
                rules={[
                  {
                    required: true,
                    message: 'Please select Transition Time!',
                  },
                ]}
                initialValue={0}
              >
                <Input type="number" placeholder="Enter number of hours" />
              </Form.Item>
              <Form.Item
                label="Step 2 Transition Time (in hours)"
                name="step_two"
                rules={[
                  {
                    required: true,
                    message: 'Please select Transition Time!',
                  },
                ]}
                initialValue={0}
              >
                <Input type="number" placeholder="Enter number of hours" />
              </Form.Item>
              <Form.Item
                label="Step 3 Transition Time (in hours)"
                name="step_three"
                rules={[
                  {
                    required: true,
                    message: 'Please select Transition Time!',
                  },
                ]}
                initialValue={0}
              >
                <Input type="number" placeholder="Enter number of hours" />
              </Form.Item>
              <Form.Item
                label="Step 4 Transition Time (in hours)"
                name="step_four"
                rules={[
                  {
                    required: true,
                    message: 'Please select Transition Time!',
                  },
                ]}
                initialValue={0}
              >
                <Input type="number" placeholder="Enter number of hours" />
              </Form.Item>
              <Form.Item
                label="Step 5 Transition Time (in hours)"
                name="step_five"
                rules={[
                  {
                    required: true,
                    message: 'Please select Transition Time!',
                  },
                ]}
                initialValue={0}
              >
                <Input type="number" placeholder="Enter number of hours" />
              </Form.Item>
              <Form.Item
                label="Step 6 Transition Time (in hours)"
                name="step_six"
                rules={[
                  {
                    required: true,
                    message: 'Please select Transition Time!',
                  },
                ]}
                initialValue={0}
              >
                <Input type="number" placeholder="Enter number of hours" />
              </Form.Item>
              <Form.Item className="submit-archive-time-btn">
                <Button
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </>
      )}
    </PageContainer>
  );
}

export default Settings;
