import { getAuth, signOut } from '@firebase/auth';
import { message } from 'antd';

/**
 * @param {History} history result of useHistory() hook
 * @param {String} handleSuccess success message
 * @param {String} handleError  failure message
 * @description logoutUser pass it t
 */

export const logoutUser = async (
  history,
  successMsg = 'User logged out',
  errorMsg = 'User logout failed'
) => {
  try {
    const auth = getAuth();
    await signOut(auth);

    message.success(successMsg);
    history.push('/login');
  } catch (error) {
    message.error(errorMsg);
  }
};

const authServices = {
  logoutUser,
};

export default authServices;
