import { useEffect, useRef } from 'react';

/**
 * @ description This hook resets scroll to top if modal was closed while scroll position was not at the top
 * @param {boolean} dependency
 */

function useResetDrawerScroll(dependency) {
  const drawerBody = useRef(null);

  useEffect(() => {
    if (dependency) {
      drawerBody.current.scrollTop = 0;
    }
  }, [drawerBody, dependency]);
  return drawerBody;
}

export default useResetDrawerScroll;
