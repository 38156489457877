import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import User from '@pages/users';
import Lead from '@pages/leads';
import Template from '@pages/template';
import Settings from '@pages/settings';

/**
 * @description AppRoutes, this component is passed as a child to layout so all routes here inherits Layout
 * @returns {JSX.Element}
 */
const AppRoutes = () => (
  <Switch>
    <Route path="/user" component={User} />
    <Route path="/lead" component={Lead} />
    <Route path="/template" component={Template} />
    <Route path="/settings" component={Settings} />
    <Redirect to="/user" />
  </Switch>
);

export default AppRoutes;
