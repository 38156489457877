/* eslint-disable react/destructuring-assignment */
import { useDispatch } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Form, Select, Button, Space, message, Input, Spin } from 'antd';

import { EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { SaveOutlined } from '@ant-design/icons';
import { stateFromHTML } from 'draft-js-import-html';
import { doc, updateDoc, where } from '@firebase/firestore';

import { db } from '@services/firebase';
import { editLeads, updateLeadsMap } from '@pages/leads/slice';
import { getCollectionData, htmlToDraftBlocks } from '@utils/utils';

const EditStepForm = (props) => {
  const [form] = Form.useForm();
  const drawerBody = useRef(null);
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  useEffect(() => {
    if (!props.openSteps) {
      setEditorState(EditorState.createEmpty());
      form.resetFields();
    } else {
      drawerBody.current.scrollTop = 0;
    }
  }, [form, props.openSteps]);

  const selectOptions = Array(props.totalSteps)
    .fill({})
    .map((_s, i) => {
      const order = i + 1;
      return { value: order, label: order };
    });

  const onSubmit = async (values) => {
    try {
      props.setLoading(true);
      const allValues = { ...values };
      const newSteps = props.steps.map((s) => {
        if (s.order === allValues.order) {
          return {
            ...s,
            ...allValues,
          };
        }
        return s;
      });
      const editedLead = {
        ...props.lead,
        steps: newSteps,
        updated_at: new Date().getTime(),
      };
      await updateDoc(doc(db, 'leads', props.lead?.key), editedLead);
      dispatch(editLeads(editedLead));
      message.success('Step updated');
      props.setOpenSteps(false);
      props.onCancel(false, null);
    } catch (err) {
      message.error('Error updating Step');
    } finally {
      props.setLoading(false);
      dispatch(updateLeadsMap());
    }
  };
  return (
    <div className="custom-drawer-body" ref={drawerBody}>
      <Form
        form={form}
        forceRender
        layout="vertical"
        onFinish={onSubmit}
        onFieldsChange={(changedField) => {
          try {
            const { name, value } = changedField[0];
            if (name[0] === 'order') {
              const template = props.steps.find((s) => s.order === value);
              form.setFieldsValue({
                template: null,
                transition_time: template?.transition_time,
                template_text: template?.template_text ?? 'No Steps here!',
              });
              setEditorState(htmlToDraftBlocks(template?.template_text ?? 'No Steps here!'));
            }
            if (name[0] === 'template') {
              const template = props.templatesList.find((s) => s.name === value);
              form.setFieldsValue({
                template_text: template?.description ?? 'No Steps here!',
              });
              setEditorState(
                EditorState.createWithContent(
                  stateFromHTML(template?.description ?? 'No Steps here!')
                )
              );
            }
          } catch (error) {
            error.message.toUpperCase();
          }
        }}
      >
        <Space direction="vertical" size="large">
          <Form.Item
            label="Step Order"
            name="order"
            rules={[
              {
                required: true,
                message: 'Please select order!',
              },
            ]}
            help="Select Step Order to edit"
          >
            <Select placeholder="Step Order" options={selectOptions} />
          </Form.Item>
          <Form.Item
            label="Select a Template"
            name="template"
            help="Select Pre-defined template to edit"
          >
            <Select
              placeholder="Templates"
              options={props.templatesList.map((t) => ({ value: t.name, label: t.name }))}
            />
          </Form.Item>
          <Form.Item
            label="Transition Time"
            name="transition_time"
            help="Select transition time in hours"
          >
            <Input placeholder="Transition Time" type="number" />
          </Form.Item>
          <Form.Item
            label="Template Text"
            name="template_text"
            rules={[
              {
                required: true,
                message: 'Please enter template text',
              },
            ]}
          >
            <Editor
              editorState={editorState}
              editorClassName="editor-class"
              editorStyle={{ height: 380 }}
              onEditorStateChange={(draft) => {
                setEditorState(draft);
              }}
              onChange={(draft) => {
                form.setFieldsValue({
                  template_text: draftToHtml(draft),
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={props.loading}
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              style={{ minWidth: 100 }}
            >
              Update Step
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
};

function EditSteps({ allProps: { openSteps, setOpenSteps, steps, totalSteps, lead, onCancel } }) {
  const [loading, setLoading] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);

  useEffect(() => {
    getCollectionData('templates', [where('is_deleted', '==', false)]).then(setTemplatesList);
  }, []);

  return (
    <Drawer visible={openSteps} width={600} onClose={() => setOpenSteps(false)} title="Edit Step">
      <Spin spinning={loading}>
        <EditStepForm
          steps={steps}
          totalSteps={totalSteps}
          lead={lead}
          onCancel={onCancel}
          openSteps={openSteps}
          templatesList={templatesList}
          setLoading={() => setLoading()}
          loading={loading}
        />
      </Spin>
    </Drawer>
  );
}

export default EditSteps;
