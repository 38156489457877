import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import enUS from 'antd/es/locale/en_US';

import store from '@store';
import App from '@/App';

import '@/index.less';
import '@utils/utils.less';

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={enUS}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);
