import isEmpty from 'lodash/isEmpty';
import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  leads: [],
  searching: false,
  refetch: true,
  loading: true,
  leadsMap: {},
  importError: false,
};

export const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setLeads(state, action) {
      state.leads = action.payload;
      state.loading = false;
      state.refetch = false;
    },
    addNewLeads(state, action) {
      state.leads = [...action.payload, ...current(state.leads)];
    },
    deleteLeads(state, action) {
      state.leads = current(state.leads).filter((item) => item.key !== action.payload);
    },
    editLeads(state, action) {
      state.leads = current(state.leads).map((content) =>
        content.key === action.payload.key ? { ...content, ...action.payload } : content
      );
    },
    searchLeads(state, action) {
      state.searching = !isEmpty(action.payload);
    },
    clearSearch(state) {
      state.searching = false;
      state.filterLeads = [];
    },
    refetchOrUpdate(state) {
      state.refetch = true;
    },
    updateLeadsMap(state) {
      state.leadsMap = {};
      state.leads.forEach((lead) => {
        state.leadsMap[lead.instagram] = lead;
      });
    },
    setImportError(state, action) {
      state.importError = action.payload;
    },
  },
});

export const {
  setLeads,
  editLeads,
  addNewLeads,
  searchLeads,
  clearSearch,
  deleteLeads,
  updateLeadsMap,
  setLoading,
  setImportError,
  refetchOrUpdate,
} = leadsSlice.actions;

export default leadsSlice.reducer;
