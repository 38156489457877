import { configureStore } from '@reduxjs/toolkit';
import userReducer from '@store/auth';
import clientReducer from '@pages/users/slice';
import templateReducer from '@pages/template/slice';
import leadsReducer from '@pages/leads/slice';

const store = configureStore({
  reducer: {
    user: userReducer,
    leads: leadsReducer,
    client: clientReducer,
    template: templateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
