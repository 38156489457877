import React from 'react';
import { GrUser, GrUserAdmin } from 'react-icons/gr';
import { IoTrashBinOutline } from 'react-icons/io5';

const TabWithIcon = ({ icon, text }) => (
  <span className="flex_center">
    {icon}
    {text}
  </span>
);

const tabList = [
  {
    tab: <TabWithIcon text="Users" icon={<GrUser style={{ marginRight: 8 }} />} />,
    key: 'users',
  },
  {
    tab: <TabWithIcon text="Admin" icon={<GrUserAdmin style={{ marginRight: 8 }} />} />,
    key: 'admins',
  },
  {
    tab: <TabWithIcon text="Trash" icon={<IoTrashBinOutline style={{ marginRight: 8 }} />} />,
    key: 'trash',
  },
];

export default tabList;
