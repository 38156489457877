import React from 'react';
import { Spin } from 'antd';

function Loading({ loading, children }) {
  if (loading) {
    return (
      <div className="cover_center flex_center">
        <Spin style={{ marginTop: '20vh' }} />
      </div>
    );
  }
  return <>{children}</>;
}

export default Loading;
