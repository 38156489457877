import { collection, getDocs, query } from '@firebase/firestore';
import { EditorState, ContentState } from 'draft-js';
import { db } from '@services/firebase';
import htmlToDraft from 'html-to-draftjs';

/**
 * @description this function is used to return correct editor state from html
 * @param {string} html
 * @returns
 */
export const htmlToDraftBlocks = (html) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};

/**
 * @description - This function is used to get the form item validation rule
 * @param {string} pleaseEnter required
 * @param {string} type required
 * @returns form rules object
 */
export const createFormRule = (otherRules = {}) => ({
  ...otherRules,
});

/**
 * @description returns array of collection values
 * @param {String} collectionName
 * @param {WhereFilterOp[]} whereQuery
 * @returns {Array} collection values
 */

export const getCollectionData = async (collectionName, whereQuery = []) => {
  const tempArr = [];
  const q = query(collection(db, collectionName), ...whereQuery);
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    tempArr.push(data);
  });
  return tempArr;
};

const utils = {
  getCollectionData,
  createFormRule,
  htmlToDraftBlocks,
};

export default utils;
