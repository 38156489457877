import Joi from 'joi';

/**
 * @description Joi Schema to validate leads data
 * @returns {Joi.ObjectSchema<any>} Joi Schema instance
 */
export const leadSchema = Joi.object({
  key: Joi.string().guid({ version: 'uuidv4' }).required(),
  user_id: Joi.string().alphanum(),
  instagram: Joi.string().required(),
  instagram_link: Joi.string().required(),
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  website: Joi.string(),
  specialty: Joi.string().required(),
  total_steps: Joi.number().required(),
  steps: Joi.array()
    .items(
      Joi.object({
        order: Joi.number().required(),
        transition_time: Joi.number(),
        completion_time: Joi.number(),
        is_completed: Joi.boolean().required(),
        template_text: Joi.string(),
      })
    )
    .required(),
  created_at: Joi.date().required(),
  updated_at: Joi.any().allow(Joi.date(), null).required(),
  invisible_time: Joi.any().allow(Joi.date(), null).required(),
  current_step: Joi.number().required(),
  is_archive: Joi.boolean().required(),
  archive_timestamp: Joi.any().allow(Joi.date(), null).required(),
  is_published: Joi.boolean().required(),
  is_deleted: Joi.boolean().required(),
  archive_hours: Joi.number().required(),
});

const validation = {
  leadSchema,
};

export default validation;
