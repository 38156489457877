/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
import { notification } from 'antd';
import chunk from 'lodash/chunk';
import { db } from '@services/firebase';
import { collection, doc, getDocs, query, where, writeBatch } from '@firebase/firestore';

/**
 * @description This is a helper function to bulk update a field in a collection
 * @param {object} { searchValues, searchIn, searchField, replaceWith, operationType }
 * @summary Following is the structure of the param object
 * { searchValues: [arrays of values to search collection docs with],
 *   searchIn: name of the collection,
 *   searchField: name of field in doc with which searchValues array's items are matched with,
 *   replaceWith: provide an object to update matched doc; example: {key: value},
 *   operationType: type of operation, If setting docs status from unpublished to publish you can pass 'Published'
 *                 this values is used to show toast notification message
 *  }
 * @returns {void}
 */
const bulkUpdate = async ({ searchValues, searchIn, searchField, replaceWith, operationType }) => {
  try {
    const batch = writeBatch(db);

    if (searchValues.length <= 10) {
      const resultsQuery = query(collection(db, searchIn), where(searchField, 'in', searchValues));
      const results = await getDocs(resultsQuery);
      results.forEach((docI) => {
        batch.update(docI.ref, { ...replaceWith });
      });
    } else {
      const chunks = chunk(searchValues, 10);
      for (let i = 0; i < chunks.length; i += 1) {
        const resultsQuery = query(collection(db, searchIn), where(searchField, 'in', chunks[i]));
        const results = await getDocs(resultsQuery);
        results.forEach((docI) => {
          batch.update(docI.ref, { ...replaceWith });
        });
      }
    }
    await batch.commit();
    notification.success({
      message: `Operation Complete`,
      description: `${operationType} Selections Successfully`,
    });
  } catch (error) {
    notification.error({
      message: `Operation Failed`,
      description: `Can't perform selected operation`,
    });
  }
};

/**
 * @description This is a helper function to bulk create new a docs in a collection
 * @param {object} { searchValues, searchIn, searchField, replaceWith, operationType }
 * @summary Following is the structure of the param object
 * { data: [arrays of values to add to collection],
 *   createIn: name of the collection to add `data`,
 *   idField:  this will be the key name in the data array's items which are objects, value of this will be the path of created doc
 *   message: Show message after operation is successful
 *  }
 * @returns {void}
 */
const bulkCreate = async ({ data, createIn, idField, message }) => {
  try {
    const batch = writeBatch(db);
    for (let i = 0; i < data.length; i += 1) {
      const docRef = doc(db, createIn, `${data[i][idField]}`);
      batch.set(docRef, data[i]);
    }
    await batch.commit();
    notification.success({
      message: `Operation Complete`,
      description: `${message} Successfully`,
    });
  } catch (error) {
    notification.error({
      message: `Operation Failed`,
      description: `Can't perform selected operation`,
    });
  }
};

const firebaseUtils = {
  bulkUpdate,
  bulkCreate,
};

export { firebaseUtils as default, bulkUpdate, bulkCreate };
