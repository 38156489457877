import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginForm, ProFormText } from '@ant-design/pro-form';
import { Alert, Button, Spin, notification, Form, message, Image } from 'antd';
import { collection, getDocs, query, where } from '@firebase/firestore';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { db } from '@services/firebase';
import logo from '@/logo.png';
import loginStyles from './styles';

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 18, marginBottom: 5, color: 'white' }} spin />
  );

  const getUserByEmail = async (email) => {
    const q = query(collection(db, 'users'), where('email', '==', `${email}`));
    let isUserAdmin = false;
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const userData = doc.data();
      if (userData.isAdmin) {
        isUserAdmin = true;
      }
    });
    return isUserAdmin;
  };

  const handleError = (errorCode) => {
    setIsLoading(false);
    if (errorCode === 'auth/invalid-email' || errorCode === 'auth/user-not-found') {
      setErrorMessage('Please enter valid credentials');
    } else if (errorCode === 'auth/wrong-password') {
      setErrorMessage('Your password is incorrect');
    } else {
      setErrorMessage('Something went wrong! Please try again later');
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    setErrorMessage('');
    const auth = getAuth();
    const isUserAdmin = await getUserByEmail(values.username);
    if (isUserAdmin) {
      if (isResetPassword) {
        sendPasswordResetEmail(auth, values.username)
          .then(() => {
            setIsLoading(false);
            form.resetFields();
            notification.success({
              description: 'Password reset link has been sent to your email successfully',
            });
            setIsResetPassword(false);
          })
          .catch((error) => {
            const errorCode = error.code;
            handleError(errorCode);
          });
      } else {
        signInWithEmailAndPassword(auth, values.username, values.password)
          .then((userCredential) => {
            const { user } = userCredential;
            if (isEmpty(user)) {
              message.info('Something went wrong! Please try again.');
            } else {
              history.push('/user');
            }
            setIsLoading(false);
          })
          .catch((error) => {
            const errorCode = error.code;
            handleError(errorCode);
          });
      }
    } else {
      setIsLoading(false);
      setErrorMessage('Sorry! only admins can login here.');
    }
  };

  return (
    <div style={loginStyles.mainContainer}>
      <div style={loginStyles.cardContainer}>
        <div style={loginStyles.imageContainer}>
          <Image preview={false} src={logo} width={150} style={loginStyles.br100} />
        </div>
        <div>
          <LoginForm
            form={form}
            onFinish={onFinish}
            subTitle="  "
            submitter={{
              render: () => [],
            }}
          >
            {errorMessage ? (
              <Alert message={errorMessage} type="error" style={loginStyles.mt20} />
            ) : null}
            <ProFormText
              name="username"
              fieldProps={{
                size: 'large',
                prefix: <UserOutlined className="prefixIcon" />,
              }}
              placeholder="Enter email"
              rules={[
                {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email!',
                },
              ]}
            />
            {!isResetPassword ? (
              <ProFormText.Password
                name="password"
                fieldProps={{
                  size: 'large',
                  prefix: <LockOutlined className="prefixIcon" />,
                }}
                placeholder="Enter password"
                rules={[
                  {
                    required: true,
                    min: 6,
                    message: 'Please enter a valid password！',
                  },
                ]}
              />
            ) : null}

            <Button
              size="large"
              type="primary"
              onClick={() => form?.submit()}
              style={loginStyles.buttonStyle}
            >
              {isLoading ? (
                <Spin indicator={antIcon} />
              ) : isResetPassword ? (
                'Send reset link'
              ) : (
                'Login'
              )}
            </Button>
            <p
              role="presentation"
              className="hover-cursor-clickable"
              style={loginStyles.linkStyle}
              onClick={() => {
                form.resetFields();
                setIsResetPassword(!isResetPassword);
              }}
            >
              {!isResetPassword ? 'Forgot Password?' : 'Back to login'}
            </p>
          </LoginForm>
        </div>
      </div>
    </div>
  );
};

export default Login;
