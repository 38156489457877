/* eslint-disable react/destructuring-assignment */
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { doc, updateDoc, setDoc, where, getDoc } from '@firebase/firestore';
import { SaveOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Drawer,
  Form,
  Input,
  Button,
  message,
  Select,
  Tooltip,
  AutoComplete,
  Space,
  Spin,
} from 'antd';

import { db } from '@services/firebase';
import { getCollectionData } from '@utils/utils';
import { leadSchema } from '@pages/leads/validation';
import EditSteps from '@pages/leads/components/EditSteps';
import useFillForm from '@hooks/useFillForm';
import useResetDrawerScroll from '@hooks/useResetDrawerScroll';
import { editLeads, addNewLeads, updateLeadsMap } from '@pages/leads/slice';
import { formatLeads, stepSchema } from '@pages/leads/utils';

/**
 * @description Component to handle lead editing
 * @param {boolean} show set modal visibility
 * @param {{}} lead lead object to edit
 * @param {setState} onCancel function to set Modal visibility
 * @returns void
 */

const EditForm = (props) => {
  const [form] = Form.useForm();
  const [defaultArchiveTime, setDefaultArchiveTime] = useState(720);
  const [usersList, setUsersList] = useState([]);

  const getArchiveConfig = async () => {
    const archiveRef = doc(db, 'config', 'archive');
    const archive = await getDoc(archiveRef);
    if (archive.exists()) {
      const archiveVals = archive.data();
      setDefaultArchiveTime(archiveVals?.archive_time);
    }
  };
  useEffect(() => {
    getCollectionData('users', [where('isAdmin', '==', false)]).then(setUsersList);
    getArchiveConfig();
    if (!props.show) {
      form.resetFields();
    }
  }, [form, props.show]);

  useFillForm(props.lead, form, {
    archive_hours: props.lead?.archive_hours
      ? Math.floor(props.lead?.archive_hours / 24)
      : Math.floor(defaultArchiveTime / 24),
  });

  return (
    <>
      <Form form={form} onFinish={props.onSubmit} layout="vertical">
        {props.newLead && (
          <Form.Item
            name="key"
            label="Key"
            rules={[
              {
                required: true,
                message: 'Please input key',
              },
            ]}
          >
            <Input
              addonAfter={
                <Tooltip title="Add a random key">
                  <Button type="primary" onClick={() => form.setFieldsValue({ key: v4() })}>
                    Generate
                  </Button>
                </Tooltip>
              }
            />
          </Form.Item>
        )}
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[
            {
              required: true,
              message: 'Please input first name',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[
            {
              required: true,
              message: 'Please input last name',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="user_id"
          label="User ID"
          rules={[
            {
              required: true,
              message: 'Please input User ID',
            },
          ]}
        >
          <AutoComplete
            options={props.userOptions}
            onSelect={(_, u) => form.setFieldsValue({ user_id: u.id })}
            onSearch={(searchStr) => {
              const usersSearchList = usersList.filter((u) =>
                u.name.toLowerCase().includes(searchStr.toLowerCase())
              );
              props.setUserOptions(usersSearchList.map((u) => ({ value: u.name, ...u })));
            }}
          />
        </Form.Item>
        <Form.Item
          name="specialty"
          label="Specialty"
          rules={[
            {
              required: true,
              message: 'Please input specialty',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="instagram_link"
          label="Instagram Link"
          rules={[
            {
              required: true,
              message: 'Please input instagram link',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="instagram"
          label="Instagram"
          rules={[
            {
              required: true,
              message: 'Please input instagram handle',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="website"
          label="Website"
          rules={[
            {
              required: true,
              message: 'Please input website url',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="total_steps"
          label="Total Steps"
          rules={[
            {
              required: true,
              message: 'Please input total step',
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Archive Time (in days)"
          name="archive_hours"
          rules={[
            {
              required: true,
              message: 'Please select archive time!',
            },
          ]}
          initialValue={0}
        >
          <Input type="number" placeholder="Enter number of days" />
        </Form.Item>
        <Form.Item
          name="is_published"
          label="Publish"
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Select
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
          />
        </Form.Item>
        {!props.newLead && (
          <>
            <Form.Item
              name="current_step"
              label="Current Step"
              rules={[
                {
                  required: true,
                  message: 'Please input current step',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              name="is_archive"
              label="Archive"
              rules={[
                {
                  required: true,
                  message: 'Please select an option',
                },
              ]}
            >
              <Select
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
              />
            </Form.Item>
          </>
        )}
        <Form.Item wrapperCol={{ span: 24 }}>
          <Button
            loading={props.loading}
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
            className="flex_center"
          >
            {props.newLead ? 'Save Lead' : 'Update Lead'}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

function EditLead({ show, lead, onCancel, newLead, setUserOptions, userOptions }) {
  const dispatch = useDispatch();
  const drawerBody = useResetDrawerScroll(show);
  const [loading, setLoading] = useState(false);
  const [openSteps, setOpenSteps] = useState(false);

  const onSubmit = async (values) => {
    const newLeadObj = { ...values };
    newLeadObj.archive_hours = Number(Math.floor(newLeadObj.archive_hours) * 24);
    try {
      setLoading(true);
      if (newLead) {
        const addLead = await formatLeads([newLeadObj]);
        const result = leadSchema.validate(addLead[0]);
        if (!result.error) {
          await setDoc(doc(db, 'leads', `${addLead[0].key}`), addLead[0]);
          dispatch(addNewLeads([addLead[0]]));
        }
      } else {
        const getPrevSteps = Array.from(lead.steps);
        if (Math.floor(lead.total_steps) !== Math.floor(newLeadObj.total_steps)) {
          if (Math.floor(lead.total_steps) < Math.floor(newLeadObj.total_steps)) {
            const diff = Math.floor(newLeadObj.total_steps) - Math.floor(lead.total_steps);
            for (
              let i = Math.floor(newLeadObj.total_steps) - diff;
              i < Math.floor(newLeadObj.total_steps);
              i += 1
            ) {
              let order = i;
              // eslint-disable-next-line no-await-in-loop
              getPrevSteps.push(await stepSchema((order += 1)));
            }
            newLeadObj.steps = getPrevSteps;
          } else {
            getPrevSteps.length = Math.floor(newLeadObj.total_steps);
            newLeadObj.steps = getPrevSteps;
          }
        }
        await updateDoc(doc(db, 'leads', lead.key), {
          ...lead,
          ...newLeadObj,
          updated_at: new Date().getTime(),
        });
        dispatch(editLeads({ ...lead, ...newLeadObj, updated_at: new Date().getTime() }));
      }
      message.success(newLead ? 'Lead Added Successfully' : 'Lead Edited Successfully');
      onCancel(false, null);
    } catch (error) {
      if (newLead) {
        message.error('Error Adding New Lead');
      } else {
        message.error('Error Editing Lead');
      }
    } finally {
      setLoading(false);
      dispatch(updateLeadsMap());
    }
  };

  return (
    <Drawer
      className="edit-lead"
      forceRender
      visible={show}
      onClose={() => onCancel(false, null)}
      closable={false}
      title={
        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          {newLead ? 'Create New Lead ' : 'Edit Lead'}
          <div>
            {!newLead && (
              <Button
                type="primary"
                onClick={() => setOpenSteps(true)}
                icon={<EditOutlined />}
                style={{ marginRight: 10 }}
              >
                Edit Steps
              </Button>
            )}
            <Button onClick={() => onCancel(false, null)} type="text" icon={<CloseOutlined />} />
          </div>
        </Space>
      }
      width={600}
    >
      <EditSteps
        allProps={{
          lead,
          onCancel,
          openSteps,
          setOpenSteps,
          steps: lead?.steps,
          totalSteps: Math.floor(lead?.total_steps || 0),
        }}
      />
      <Spin spinning={loading}>
        <div
          className="custom-drawer-body"
          style={{ height: '95vh', overflow: 'auto' }}
          ref={drawerBody}
        >
          <EditForm
            onSubmit={onSubmit}
            newLead={newLead}
            loading={loading}
            show={show}
            lead={lead}
            userOptions={userOptions}
            setUserOptions={setUserOptions}
          />
        </div>
      </Spin>
    </Drawer>
  );
}

export default EditLead;
export { EditForm };
