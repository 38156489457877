/**
 * @description This function responsible to return valid template schema
 * @returns
 */
export const createTemplateSchema = (values) => ({
  name: values.name,
  description: values.description,
  default_step: Number(values.default_step),
  is_deleted: false,
});

export { createTemplateSchema as default };
