import React from 'react';
import { doc, deleteDoc, updateDoc } from '@firebase/firestore';
import { Tooltip, Button, message, Typography, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined, MinusOutlined } from '@ant-design/icons';

import store from '@store';
import { db } from '@services/firebase';
import { deleteTemplate, editTemplate } from '@pages/template/slice';

/**
 * @description This function generates and returns ProTable Column Config Object
 * @param {Function} openModal
 * @returns ProTable columns Config
 */
const columns = (openModal, tab) => {
  const { dispatch } = store;
  /**
   * @description Deletes the template from fire store
   * @param {String} key
   */
  const handleDelete = async (record, temp = true) => {
    try {
      const { templateId: key } = record;
      const templateRef = doc(db, 'templates', key);
      if (temp) {
        await updateDoc(templateRef, { ...record, is_deleted: true });
      } else {
        await deleteDoc(templateRef);
      }
      dispatch(deleteTemplate(key));
      message.success('Template Deleted');
    } catch (error) {
      message.error('Cant delete Template');
    }
  };

  /**
   * @description Handle Restore the lead from fire store
   * @param {String} key
   */
  const handleRestore = async (record) => {
    try {
      const { templateId: key } = record;
      const leadRef = doc(db, 'templates', key);
      await updateDoc(leadRef, { ...record, is_deleted: false });
      dispatch(deleteTemplate(key));
      message.success('Template Restored');
    } catch (error) {
      message.error('Cant restore template');
    }
  };

  /**
   * @param {{}} record lead object
   * @description updates or clears default_steps key
   */
  const clearDefault = async (record) => {
    try {
      const { templateId: key } = record;
      const templateRef = doc(db, 'templates', key);
      await updateDoc(templateRef, { ...record, default_step: null });
      dispatch(editTemplate(record));
      message.success('Template default cleared');
    } catch (error) {
      message.error('Error Editing Template');
    }
  };

  const colConfig = [
    {
      title: 'Template Id',
      dataIndex: 'templateId',
      key: 'key',
      width: 160,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 160,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      hideInSearch: true,
      width: 600,
      render: (text) => (
        <Tooltip title="Expand to see template preview">
          <Typography.Text ellipsis style={{ width: 600 }}>
            {text}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: 'Clear Default',
      key: 'clear',
      valueType: 'option',
      hideInSearch: true,
      fixed: 'right',
      width: 120,
      align: 'center',
      render: (_, record) => (
        <Tooltip title="Remove default step from this template.">
          <Button
            shape="circle"
            size="small"
            icon={<MinusOutlined />}
            onClick={() => clearDefault({ ...record, default_step: null })}
          />
        </Tooltip>
      ),
    },
    {
      title: 'Edit',
      key: 'edit',
      valueType: 'option',
      hideInSearch: true,
      fixed: 'right',
      width: 80,
      align: 'center',
      render: (_, record) => (
        <Tooltip title="Edit this template">
          <Button
            shape="circle"
            size="small"
            icon={<EditOutlined />}
            onClick={() => openModal(true, record)}
          />
        </Tooltip>
      ),
    },
    {
      title: 'Delete',
      valueType: 'option',
      key: 'delete',
      fixed: 'right',
      hideInSearch: true,
      width: 80,
      align: 'center',
      render: (text, record) => (
        <Popconfirm
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleDelete(record)}
        >
          <Button danger shape="circle" size="small" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
    {
      title: 'Restore',
      valueType: 'option',
      key: 'restore',
      fixed: 'right',
      hideInSearch: true,
      width: 80,
      align: 'center',
      render: (text, record) => (
        <Tooltip title="Restore this template">
          <Button
            shape="circle"
            size="small"
            icon={<RetweetOutlined />}
            onClick={() => handleRestore(record)}
          />
        </Tooltip>
      ),
    },
    {
      title: 'Delete',
      valueType: 'option',
      key: 'deleteFinally',
      fixed: 'right',
      hideInSearch: true,
      width: 80,
      align: 'center',
      render: (text, record) => (
        <Tooltip placement="left" title="Delete this template permanently">
          <Button
            danger
            shape="circle"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record, false)}
          />
        </Tooltip>
      ),
    },
  ];
  return tab === 'trash'
    ? colConfig.filter((c) => !['edit', 'delete'].includes(c.key))
    : colConfig.filter((c) => !['restore', 'deleteFinally'].includes(c.key));
};

export default columns;
