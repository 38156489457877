import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

/**
 * @description This hook is used dynamically get the query string used to navigate between tabs
 * @returns query string
 */
function useTabKey(cb = false) {
  const location = useLocation();
  const pairs = location.search.slice(1).split('&');
  const result = {};
  pairs.forEach((pair) => {
    pair = pair.split('=');
    if (pair.length) {
      result[pair[0]] = decodeURIComponent(pair[1] || '');
      result[pair[0]] = decodeURIComponent(pair[1]?.replace(/\+/g, '%20') || '');
    }
  });
  const paramsData = JSON.parse(JSON.stringify(result));
  const activeTab = paramsData?.type;
  const [activeTabKey, setActiveTabKey] = useState(activeTab);
  useEffect(() => {
    setActiveTabKey(activeTab);
    if (cb) {
      cb();
    }
  }, [activeTab, cb]);
  return activeTabKey;
}

export default useTabKey;
