import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  template: [],
  filterTemplate: [],
  searching: false,
  refetch: true,
  loading: true,
  editOrAddTemplate: {
    selectedTemplate: {},
    openAddTemplate: false,
  },
};

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setTemplate(state, action) {
      state.template = action.payload;
      state.refetch = false;
      state.loading = false;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setEditOrAddTemplate(state, action) {
      state.editOrAddTemplate = action.payload;
    },
    addNewTemplate(state, action) {
      state.template = [action.payload, ...current(state.template)];
    },
    deleteTemplate(state, action) {
      state.template = current(state.template).filter((item) => item.templateId !== action.payload);
      state.filterTemplate = current(state.filterTemplate).filter(
        (item) => item.templateId !== action.payload
      );
    },
    editTemplate(state, action) {
      state.template = current(state.template).map((content) =>
        content.templateId === action.payload.templateId
          ? { ...content, ...action.payload }
          : content
      );
      state.filterTemplate = current(state.filterTemplate).map((content) =>
        content.templateId === action.payload.templateId
          ? { ...content, ...action.payload }
          : content
      );
    },
    searchTemplates(state, action) {
      let isEquivalent = false;
      state.searching = true;
      if (typeof action.payload === 'string') {
        state.filterTemplate = current(state.template).filter((template) =>
          JSON.stringify(template).includes(action.payload)
        );
      } else {
        state.filterTemplate = current(state.template).filter((template) => {
          const searchParams = Object.keys(action.payload);
          for (let i = 0; i < searchParams.length; i += 1) {
            isEquivalent = false;
            const pattern = `${action.payload[searchParams[i]]}`.toLowerCase();
            const regex = new RegExp(pattern);
            if (searchParams[i] === 'key') {
              if (regex.test(template.templateId.toLowerCase())) {
                isEquivalent = true;
                break;
              }
            } else if (regex.test(template[searchParams[i]].toLowerCase())) {
              isEquivalent = true;
              break;
            }
          }
          return isEquivalent;
        });
      }
    },
    clearSearch(state) {
      state.searching = false;
      state.filterTemplate = [];
    },
    refetchOrUpdate(state) {
      state.refetch = true;
    },
  },
});

export const {
  setTemplate,
  addNewTemplate,
  deleteTemplate,
  editTemplate,
  searchTemplates,
  clearSearch,
  refetchOrUpdate,
  setLoading,
  setEditOrAddTemplate,
} = templateSlice.actions;

export default templateSlice.reducer;
