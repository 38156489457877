import React from 'react';
import ProTable from '@ant-design/pro-table';
import { Tooltip, Button, Typography } from 'antd';
import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';

/**
 * @description This is a ProTable which is nested under each leads row, This displays state of each steps in details.
 * @param {Row Record} record
 * @returns {JSX.Element} Table Nested in Leads Records
 */

const expandedRowRender = (record) => {
  const data = record.steps;
  if (!record.steps.length) {
    return <Typography.Text>No Steps Assigned Yet</Typography.Text>;
  }
  return (
    <ProTable
      columns={[
        {
          dataIndex: 'order',
          title: 'Step',
          width: 60,
          render: (text) => (
            <Button size="small" shape="circle" type="primary">
              {text}
            </Button>
          ),
        },
        {
          width: 80,
          dataIndex: 'is_completed',
          title: 'Completed',
          renderText: (text) => (text ? 'Yes' : 'No'),
        },
        {
          width: 250,
          dataIndex: 'transition_time',
          title: 'Transition Time (H)',
          valueType: 'number',
        },
        {
          dataIndex: 'completion_time',
          title: 'Completed At',
          valueType: 'date',
          fieldProps: {
            format: 'DD-MM-YYYY:HH:mm',
            showTime: true,
          },
        },
      ]}
      headerTitle={false}
      search={false}
      options={false}
      dataSource={data}
      pagination={false}
      style={{ paddingTop: 20 }}
    />
  );
};

export const expandIcon = ({ expanded, onExpand, record }) => (
  <Tooltip title="See Steps Details">
    {expanded ? (
      <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
    ) : (
      <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
    )}
  </Tooltip>
);

export default expandedRowRender;
