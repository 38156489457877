import { v4 } from 'uuid';
import { getCollectionData } from '@utils/utils';
import { where, doc, getDoc, setDoc } from '@firebase/firestore';
import { db } from '@services/firebase';
import { createUserWithEmailAndPassword, getAuth } from '@firebase/auth';
import faker from 'faker';

/**
 * @description Return Modified Array Of Leads Objects
 * @param {Record<string, any>[]} leadsArray
 * @returns {Promise<Record<string, any>[]>} modified Array Of Leads Objects
 */
export async function formatLeads(leadsArray) {
  const templates = await getCollectionData('templates', [where('is_deleted', '==', false)]);
  const transitionRef = doc(db, 'config', 'transition');
  const archiveRef = doc(db, 'config', 'archive');
  const transition = await getDoc(transitionRef);
  const archive = await getDoc(archiveRef);
  const archiveTime = Math.floor(archive.archive_time ?? 720);
  const defaultTransition = {
    step_one: 24,
    step_two: 24,
    step_three: 24,
    step_four: 24,
    step_five: 24,
    step_six: 24,
  };
  const stepTransitionKeys = [
    'step_one',
    'step_two',
    'step_three',
    'step_four',
    'step_five',
    'step_six',
  ];
  let transitionTime = defaultTransition;

  if (transition.exists()) {
    const transitionVals = transition.data();
    if (Object.keys(transitionVals).length >= 6) {
      transitionTime = transitionVals;
    }
  }

  const leadSchema = {
    created_at: new Date().getTime(),
    updated_at: new Date().getTime(),
    invisible_time: null,
    current_step: 1,
    is_archive: false,
    archive_timestamp: null,
    is_published: false,
    is_deleted: false,
    archive_hours: archiveTime,
  };

  return leadsArray.map((elem) => {
    // eslint-disable-next-line camelcase
    const { total_steps } = elem;
    const steps = Array(Number(total_steps)).fill({});

    return {
      key: v4(),
      steps: steps.map((_elem, stepsIndex) => ({
        order: stepsIndex + 1,
        transition_time: Number(transitionTime[stepTransitionKeys[stepsIndex]]),
        completion_time: 0,
        is_completed: false,
        template_text:
          templates.find((template) => Number(template.default_step) === Number(stepsIndex + 1))
            ?.description ?? '.',
      })),
      ...elem,
      ...leadSchema,
      total_steps: Number(total_steps),
    };
  });
}

/**
 * @description Util method to transform csv to json.
 * @param {string} csv
 * @returns {Array<{}>} Array of objects
 */
export const csvToJson = (csv) => {
  const lines = csv.split('\n');
  const result = [];
  const headers = lines[0].split(',');

  for (let i = 1; i < lines.length - 1; i += 1) {
    const obj = {};
    const currentline = lines[i].split(',');
    for (let j = 0; j < headers.length; j += 1) {
      obj[headers[j]] = currentline[j];
    }
    result.push(obj);
  }
  return result;
};

/**
 * @description utility helper to manage generating steps object from order number
 * @param {String | Number} order
 * @returns steps object
 */
export const stepSchema = async (order) => {
  const templates = await getCollectionData('templates', [
    where('default_step', '==', order.toString()),
  ]);
  const findTemplate = templates.find((t) => t.default_step === order.toString());
  return {
    order,
    template_text: findTemplate?.description ?? '',
    transition_time: null,
    completion_time: null,
    is_completed: false,
  };
};

const user = () => ({
  name: faker.name.findName(),
  email: faker.internet.email(),
  password: '123456',
});

const createUser = async () => {
  const auth = getAuth();
  const values = user();
  const creatingUser = await createUserWithEmailAndPassword(auth, values.email, values.password);
  const createdUser = await creatingUser.user;
  const userData = {
    name: values.name,
    id: createdUser.uid,
    email: values.email,
    createdAt: new Date().getTime(),
    isAdmin: false,
    isDeleted: false,
  };
  await setDoc(doc(db, 'users', `${userData.id}`), userData);
  return userData;
};

export const generateLead = async () => {
  const x = await createUser();
  const instagram = `@${faker.internet.userName()}`;
  const leadSchema = {
    user_id: x.id,
    instagram,
    instagram_link: `https://www.instagram.com/${instagram}/?hl=en`,
    first_name: faker.name.firstName(),
    last_name: faker.name.lastName(),
    website: faker.internet.domainName(),
    specialty: faker.name.jobTitle(),
    total_steps: 4,
  };
  return leadSchema;
};

const utils = {
  formatLeads,
  csvToJson,
  stepSchema,
  generateLead,
};

export default utils;
