import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import React, { useEffect, useState, useCallback } from 'react';

import Login from '@pages/login';
import Layout from '@layouts/Layout';
import { setUser } from '@store/auth';
import Loading from '@components/Loading';

import '@/App.less';
import 'antd/dist/antd.css';
import '@ant-design/pro-form/dist/form.css';
import '@ant-design/pro-table/dist/table.css';
import '@ant-design/pro-layout/dist/layout.css';

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [loadingData, setLoadingData] = useState(false);

  const checkUserLogin = useCallback(() => {
    setLoadingData(true);
    const auth = getAuth();
    onAuthStateChanged(auth, (userA) => {
      if (userA) {
        dispatch(setUser({ id: userA.uid, email: userA.email }));
      } else {
        dispatch(setUser({}));
      }
      setLoadingData(false);
    });
  }, [dispatch]);

  useEffect(() => {
    checkUserLogin();
  }, [checkUserLogin]);

  if (!user) {
    return <Loading />;
  }

  return <Router>{loadingData ? <Loading /> : !isEmpty(user) ? <Layout /> : <Login />}</Router>;
}

export default App;
