import React from 'react';
import { Tooltip, Typography } from 'antd';

/**
 * @description This Renders Custom Text in Table Cell
 * @param {String} text
 * @param {Number | String} width
 * @returns
 */
function RenderText({ text, width }) {
  return (
    <Tooltip title={text}>
      <Typography.Text ellipsis style={{ width: width ?? 120 }}>
        {text}
      </Typography.Text>
    </Tooltip>
  );
}

export default RenderText;
