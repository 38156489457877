import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  client: [],
  filterClient: [],
  searching: false,
  refetch: true,
  loading: true,
  editOrAddUser: {
    openAddUser: false,
    selectedUser: {},
  },
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setEditOrAddUser(state, action) {
      state.editOrAddUser = action.payload;
    },
    setClient(state, action) {
      state.client = action.payload;
      state.loading = false;
      state.refetch = false;
    },
    deleteClient(state, action) {
      state.client = current(state.client).filter((item) => item.id !== action.payload);
      state.filterClient = current(state.filterClient).filter((item) => item.id !== action.payload);
    },
    searchClient(state, action) {
      let isEquivalent = false;
      state.searching = true;
      if (typeof action.payload === 'string') {
        state.filterClient = current(state.client).filter((client) =>
          JSON.stringify(client).includes(action.payload)
        );
      } else {
        state.filterClient = current(state.client).filter((client) => {
          const searchParams = Object.keys(action.payload);
          for (let i = 0; i < searchParams.length; i += 1) {
            isEquivalent = false;
            const pattern = `${action.payload[searchParams[i]]}`.toLowerCase();
            const regex = new RegExp(pattern);
            if (searchParams[i] === 'userId') {
              if (regex.test(client.id.toLowerCase())) {
                isEquivalent = true;
                break;
              }
            } else if (regex.test(client[searchParams[i]].toLowerCase())) {
              isEquivalent = true;
              break;
            }
          }
          return isEquivalent;
        });
      }
    },
    clearSearch(state) {
      state.filterClient = [];
      state.searching = false;
    },
    refetchOrUpdate(state) {
      state.refetch = true;
    },
  },
});

export const {
  setClient,
  addNewClient,
  deleteClient,
  editClient,
  searchClient,
  clearSearch,
  refetchOrUpdate,
  setLoading,
  setEditOrAddUser,
} = clientSlice.actions;

export default clientSlice.reducer;
