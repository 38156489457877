import React, { useState, useEffect } from 'react';
import { PoweroffOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Typography } from 'antd';
import ProLayout from '@ant-design/pro-layout';
import { useHistory } from 'react-router-dom';

import AppRoutes from '@layouts/Routes';
import layoutConfig from '@config/layout';
import { logoutUser } from '@services/auth';
import ErrorBoundary from '@components/ErrorBoundary';

import '@layouts/Layout/index.less';

/**
 * @description Global layout components uses Ant ProLayout
 * @returns {JSX.Element}
 */

const Layout = () => {
  const history = useHistory();
  const [pathname, setPathname] = useState(``);

  useEffect(() => {
    setPathname(`/${history.location.pathname.split('/')[1]}`);
  }, [history.location.pathname]);

  return (
    <ProLayout
      {...layoutConfig}
      location={{
        pathname,
      }}
      menuItemRender={(item, dom) => (
        <div
          role="presentation"
          onClick={() => {
            setPathname(item.path);
            history.push(
              item.path === '/lead'
                ? 'lead?type=all'
                : item.path === '/user'
                ? 'user?type=users'
                : item.path === '/template'
                ? 'template?type=all'
                : item.path
            );
          }}
        >
          {dom}
        </div>
      )}
      rightContentRender={() => (
        <div className="right-content-container">
          <Typography.Title className="app-title" level={5}>
            Engagement Hero
          </Typography.Title>
          <Popconfirm
            title="Are you sure you want to logout?"
            cancelText="Cancel"
            okText="Logout"
            onConfirm={() => logoutUser(history)}
            placement="leftTop"
          >
            <Button className="right-content-btn" type="primary" icon={<PoweroffOutlined />}>
              {/* Logout */}
            </Button>
          </Popconfirm>
        </div>
      )}
    >
      <ErrorBoundary>
        <AppRoutes />
      </ErrorBoundary>
    </ProLayout>
  );
};

export default Layout;
