import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';

/**
 * @description This hook sets the values of form fields, useful when it's needed tp fill the form when editing a record
 * @returns
 */
function useFillForm(record, form, rest = {}) {
  useEffect(() => {
    if (!isEmpty(record)) {
      form.setFieldsValue({ ...record, ...rest });
    }
  }, [form, record, rest]);
}

export default useFillForm;
