import React from 'react';
import { deleteDoc, doc, updateDoc } from '@firebase/firestore';
import { Tooltip, Tag, Button, message, DatePicker, Typography, Popconfirm } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  RetweetOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import store from '@store';
import { db } from '@services/firebase';
import { deleteLeads } from '@pages/leads/slice';
import RenderText from '@pages/leads/components/RenderText';

const { RangePicker } = DatePicker;

/**
 * @description This function generates and returns ProTable Column Config Object
 * @param {Function} openModal
 * @returns ProTable columns Config
 */
const columns = (onPressPublish, openModal, tab) => {
  const { dispatch } = store;
  /**
   * @description Deletes the lead from fire store
   * @param {String} key
   */
  const handleDelete = async (record, temp = true) => {
    try {
      const leadRef = doc(db, 'leads', record.key);
      if (temp) {
        await updateDoc(leadRef, { ...record, is_deleted: true });
      } else {
        await deleteDoc(leadRef);
      }
      dispatch(deleteLeads(record.key));
      message.success('Lead Deleted');
    } catch (error) {
      message.error('Cant delete lead');
    }
  };

  /**
   * @description Handle Restore the lead from fire store
   * @param {String} key
   */
  const handleRestore = async (record) => {
    try {
      const leadRef = doc(db, 'leads', record.key);
      await updateDoc(leadRef, { ...record, is_deleted: false });
      dispatch(deleteLeads(record.key));
      message.success('Lead Restored');
    } catch (error) {
      message.error('Cant restore lead');
    }
  };

  const colConfig = [
    {
      title: 'Date',
      hideInSearch: true,
      dataIndex: 'created_at',
      width: 240,
      valueType: 'date',
      renderFormItem: (item, { fieldProps }) => <RangePicker {...fieldProps} />,
      fieldProps: {
        placeholder: ['Start Date', 'End Date'],
        format: 'DD-MM-YYYY:HH:mm',
        showTime: true,
      },
    },
    {
      title: 'Last Update',
      dataIndex: 'updated_at',
      width: 240,
      valueType: 'date',
      hideInSearch: true,
      renderText: (text, record) => (!text ? record.created_at : text),
      renderFormItem: (item, { fieldProps }) => <RangePicker {...fieldProps} />,
      fieldProps: {
        placeholder: ['Start Date', 'End Date'],
        format: 'DD-MM-YYYY:HH:mm',
        showTime: true,
      },
    },
    {
      title: 'User ID',
      dataIndex: 'user_id',
      width: 120,
      renderText: (text) => <RenderText text={text} />,
      fieldProps: {
        placeholder: 'UserID',
      },
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      fixed: 'left',
      fieldProps: { placeholder: 'Full Name' },
      width: 160,
      render: (_, record) => (
        <RenderText text={`${record.first_name} ${record.last_name}`} width="auto" />
      ),
    },
    {
      dataIndex: 'specialty',
      title: 'Specialty',
      render: (text) => <Tag color="#3fc4ff">{text}</Tag>,
      fieldProps: {
        placeholder: 'Specialty',
      },
    },
    {
      dataIndex: 'instagram',
      title: 'Instagram',
      fieldProps: { placeholder: 'Instagram' },
    },
    {
      dataIndex: 'instagram_link',
      title: 'Instagram Link',
      hideInSearch: true,
      width: 180,
      renderText: (text) => (
        <Typography.Link ellipsis href={text}>
          {text}
        </Typography.Link>
      ),
      fieldProps: { placeholder: 'Instagram' },
    },
    {
      dataIndex: 'website',
      title: 'Website',
      fieldProps: {
        placeholder: 'Website',
      },
      renderText: (text) => <Typography.Link href={text}>{text}</Typography.Link>,
    },
    {
      title: 'Current Step',
      dataIndex: 'current_step',
      valueType: 'select',
      width: 340,
      fieldProps: {
        placeholder: 'Current Step',
      },
      valueEnum: {
        1: {
          text: '1',
        },
        2: {
          text: '2',
        },
        3: {
          text: '3',
        },
        4: {
          text: '4',
        },
      },
    },
    {
      title: 'Published',
      dataIndex: 'is_published',
      valueType: 'select',
      width: 180,
      valueEnum: {
        true: {
          text: 'Yes',
        },
        false: {
          text: 'No',
        },
      },
      renderText: (text) => (text ? 'Yes' : 'No'),
    },
    {
      title: 'Archived',
      dataIndex: 'is_archive',
      valueType: 'select',
      width: 180,
      valueEnum: {
        true: {
          text: 'Yes',
        },
        false: {
          text: 'No',
        },
      },
      renderText: (text) => (text ? 'Yes' : 'No'),
    },
    {
      dataIndex: 'archive_hours',
      title: 'Archive Time',
      width: 280,
      renderText: (text) => Math.floor(text / 24),
      hideInSearch: true,
      fieldProps: {
        placeholder: 'Archive Time',
      },
    },
    {
      title: 'Publish',
      key: 'publish',
      valueType: 'option',
      hideInSearch: true,
      fixed: 'right',
      align: 'center',
      width: 155,
      render: (_, record) => (
        <Tooltip title={`${record.is_published ? 'Unpublish' : 'Publish'} this lead`}>
          <Button
            shape="circle"
            size="small"
            icon={record.is_published ? <CloseOutlined /> : <CheckOutlined />}
            onClick={() => onPressPublish(record)}
          />
        </Tooltip>
      ),
    },
    {
      title: 'Edit',
      key: 'edit',
      valueType: 'option',
      hideInSearch: true,
      fixed: 'right',
      align: 'center',
      width: 80,
      render: (_, record) => (
        <Tooltip title="Edit this lead">
          <Button
            shape="circle"
            size="small"
            icon={<EditOutlined />}
            onClick={() => openModal(true, record)}
          />
        </Tooltip>
      ),
    },
    {
      title: 'Delete',
      valueType: 'option',
      key: 'delete',
      fixed: 'right',
      hideInSearch: true,
      width: 100,
      align: 'center',
      render: (text, record) => (
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleDelete(record)}
        >
          <Button danger shape="circle" size="small" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
    {
      title: 'Restore',
      valueType: 'option',
      key: 'restore',
      fixed: 'right',
      hideInSearch: true,
      width: 80,
      align: 'center',
      render: (text, record) => (
        <Tooltip title="Restore this lead">
          <Button
            shape="circle"
            size="small"
            icon={<RetweetOutlined />}
            onClick={() => handleRestore(record)}
          />
        </Tooltip>
      ),
    },
    {
      title: 'Delete',
      valueType: 'option',
      key: 'deleteFinally',
      fixed: 'right',
      hideInSearch: true,
      width: 80,
      align: 'center',
      render: (text, record) => (
        <Tooltip placement="left" title="Delete this lead permanently">
          <Button
            danger
            shape="circle"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record, false)}
          />
        </Tooltip>
      ),
    },
  ];

  return tab === 'trash'
    ? colConfig.filter((c) => !['edit', 'delete'].includes(c.key))
    : colConfig.filter((c) => !['restore', 'deleteFinally'].includes(c.key));
};

export default columns;
