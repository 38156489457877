import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { doc, setDoc, updateDoc } from '@firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from '@firebase/auth';
import { Alert, Button, Input, Drawer, Form, notification, message } from 'antd';

import { db } from '@services/firebase';
import Loading from '@components/Loading';
import useFillForm from '@hooks/useFillForm';
import useResetDrawerScroll from '@hooks/useResetDrawerScroll';
import { createUserSchema, createUpdateUserSchema, handleError } from '@pages/users/utils';

const AddUser = ({ drawerProps: { toggleEditUser, selectedUser, openAddUser } }) => {
  const [form] = Form.useForm();
  useFillForm(selectedUser, form);
  const drawerBody = useResetDrawerScroll(openAddUser);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const defaultState = { loading: isLoading, error: errorMessage };
  const isNewUser = isEmpty(selectedUser);
  const setStates = (overrides = {}, defaults = defaultState) => {
    const { loading, error } = { ...defaults, ...overrides };
    setIsLoading(loading);
    setErrorMessage(error);
  };

  const onClose = () => {
    form.resetFields();
    setStates({ error: '' });
    toggleEditUser(false, !isNewUser && {});
  };

  const saveUserData = async (data, type) => {
    try {
      if (type === 'add') {
        await setDoc(doc(db, 'users', `${data.id}`), data);
      } else {
        await updateDoc(doc(db, 'users', `${data.id}`), data);
      }
      setStates({ error: '', reset: false });
      notification.success({
        description: `User ${type === 'add' ? 'added' : 'updated'} successfully`,
      });
    } catch (error) {
      message.info('Something went wrong! Please try again.');
    } finally {
      form.resetFields();
      toggleEditUser(false, {});
      setIsLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      setStates({ error: '', loading: true });
      const auth = getAuth();
      const { email, password, name: _name } = values;
      if (isNewUser) {
        const createUser = await createUserWithEmailAndPassword(auth, email, password);
        const user = await createUser.user;
        const userData = createUserSchema(_name, user)('validation failed');
        return !isEmpty(user) && saveUserData(userData, 'add');
      }
      return saveUserData(createUpdateUserSchema(selectedUser, _name), 'update');
    } catch (error) {
      return handleError(error.code, setStates);
    }
  };

  return (
    <Drawer
      visible={openAddUser}
      width={450}
      onClose={onClose}
      title={isNewUser ? 'Add User' : 'Edit User'}
    >
      <Loading loading={isLoading}>
        <div className="custom-drawer-body" ref={drawerBody}>
          <Form form={form} onFinish={onFinish} layout="vertical">
            {errorMessage && (
              <Alert message={errorMessage} closable type="error" className="mb-4" />
            )}
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please enter valid email!',
                },
              ]}
            >
              <Input disabled={!isNewUser} />
            </Form.Item>
            {isNewUser && (
              <>
                <Form.Item
                  label="Password"
                  name="password"
                  required
                  rules={[
                    {
                      message: 'Password must be 6 characters long!',
                      min: 6,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  required
                  label="Re-enter Password"
                  name="confirmPassword"
                  dependencies={['password']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('The two passwords that you entered do not match!')
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ minWidth: 100 }}>
                {isNewUser ? 'Add User' : 'Update User'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Loading>
    </Drawer>
  );
};

export default AddUser;
