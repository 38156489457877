const loginStyles = {
  mainContainer: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F0F2F5',
  },
  cardContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
  imageContainer: {
    marginBottom: -60,
    borderRadius: 100,
    width: 152,
    height: 152,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  linkStyle: {
    marginTop: 10,
    float: 'right',
    color: '#3982F4',
  },
  mt20: { marginBottom: 20 },
  br100: { borderRadius: 100 },
};

export default loginStyles;
