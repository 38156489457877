import XLSX from 'xlsx';
import React, { useState } from 'react';
import { bulkCreate } from '@utils/firebase';
import ReactFileReader from 'react-file-reader';
import ProgressBar from '@components/ProgressBar';
import { UploadOutlined } from '@ant-design/icons';
import { leadSchema } from '@pages/leads/validation';
import { useDispatch, useSelector } from 'react-redux';
import { addNewLeads, setImportError } from '@pages/leads/slice';
import { formatLeads, csvToJson, generateLead } from '@pages/leads/utils';
import { Modal, Space, Tooltip, Button, message, notification } from 'antd';

window.generateLead = generateLead;
/**
 * @description Modal Component to handle leads import
 */
function ImportLeads({ modalProps: { setShowModal, showModal } }) {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const { leadsMap } = useSelector((state) => state.leads);

  const onCancel = () => {
    if (!uploading) {
      setUploading(false);
      setShowModal(false);
    }
  };

  const createUsersInDatabase = async (leadData) => {
    try {
      const uniqueLeads = [];
      const duplicateLeads = [];
      for (let i = 0; i < leadData.length; i += 1) {
        if (!leadsMap[leadData[i].instagram]) {
          uniqueLeads.push(leadData[i]);
        } else {
          duplicateLeads.push(leadData[i].instagram);
        }
      }

      if (uniqueLeads.length > 0) {
        await bulkCreate({
          data: uniqueLeads,
          createIn: 'leads',
          idField: 'key',
          message: 'Leads Imported',
        });
        dispatch(addNewLeads(uniqueLeads));
      }

      if (duplicateLeads.length > 0) {
        dispatch(
          setImportError(
            `Attempted to upload duplicate leads. \n 
            Following leads already exists in the database: \n 
            ${duplicateLeads.join(', ')}`
          )
        );
        setInterval(() => {
          dispatch(setImportError(false));
        }, 3000);
      }
    } catch (error) {
      notification.error({
        message: 'Operation failed',
        description: 'Import Failed with a fatal error',
      });
    } finally {
      setUploading(false);
      onCancel();
    }
  };

  const handleFiles = (files) => {
    setUploading(true);
    const reader = new FileReader();
    reader.onload = async (evt) => {
      try {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        const leadData = csvToJson(data);
        const formatLeadData = await formatLeads(leadData);
        let validationError = false;
        // validate lead schema pre import
        formatLeadData.forEach((lead) => {
          const result = leadSchema.validate(lead);
          if (result.error) {
            validationError = result.error.message;
          }
        });

        if (!validationError) {
          await createUsersInDatabase(formatLeadData);
        } else {
          message.error('Excel sheet schema is not allowed, incorrect fields found');
          setUploading(false);
          validationError = false;
        }
      } catch (error) {
        message.error('Failed to import!');
        setUploading(false);
      }
    };
    reader.readAsBinaryString(files[0]);
  };

  return (
    <Modal
      visible={showModal}
      onCancel={onCancel}
      cancelText="Cancel"
      cancelButtonProps={{ type: 'primary', disabled: uploading }}
      title="Import Leads"
      okButtonProps={{ style: { display: 'none' } }}
      bodyStyle={{
        padding: 0,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <ProgressBar loading={uploading} />
      <Space align="center" direction="vertical" size="large" className="fw">
        <ReactFileReader handleFiles={handleFiles} fileTypes={['.csv', '.xlsx', '.xlx']}>
          <Tooltip title="upload leads data sheet">
            <Button
              className="flex_center fw"
              type="primary"
              icon={<UploadOutlined />}
              disabled={uploading}
              style={{ marginBottom: 20 }}
            >
              Upload XLSX
            </Button>
          </Tooltip>
        </ReactFileReader>
      </Space>
    </Modal>
  );
}

export default ImportLeads;
