import React from 'react';
import {
  SmileOutlined,
  UserOutlined,
  CrownOutlined,
  FormOutlined,
  SettingOutlined,
} from '@ant-design/icons';

/**
 * @description define menu generated by Ant ProLayout
 */
const routes = {
  route: {
    path: '/',
    routes: [
      {
        key: 'login',
        path: '/login',
        name: 'Login',
        icon: <SmileOutlined />,
        hideInMenu: true,
      },
      {
        key: 'user',
        path: '/user',
        name: 'Users',
        icon: <UserOutlined />,
      },
      {
        path: '/lead',
        name: 'Leads',
        icon: <CrownOutlined />,
      },
      {
        name: 'Templates',
        icon: <FormOutlined />,
        path: '/template',
      },
      {
        name: 'Settings',
        icon: <SettingOutlined />,
        path: '/settings',
      },
    ],
  },
};

export default routes;
