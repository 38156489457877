import Joi from 'joi';

/**
 * @description Joi Schema to validate user data
 * @returns {Joi.ObjectSchema<any>} Joi Schema instance
 */
export const userSchema = Joi.object({
  name: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  createdAt: Joi.date().required(),
  isAdmin: Joi.boolean().required(),
  id: Joi.string().required(),
  isDeleted: Joi.boolean().required(),
});

const validation = {
  userSchema,
};

export default validation;
