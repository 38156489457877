import React from 'react';
import { ImStack } from 'react-icons/im';
import { IoMdPaperPlane } from 'react-icons/io';
import { IoTrashBinOutline } from 'react-icons/io5';
import { GoArchive } from 'react-icons/go';

const TabWithIcon = ({ icon, text }) => (
  <span className="flex_center">
    {icon}
    {text}
  </span>
);

const tabList = [
  {
    tab: <TabWithIcon text="All" icon={<ImStack style={{ marginRight: 8 }} />} />,
    key: 'all',
  },
  {
    tab: <TabWithIcon text="Published" icon={<IoMdPaperPlane style={{ marginRight: 8 }} />} />,
    key: 'published',
  },
  {
    tab: <TabWithIcon text="Archived" icon={<GoArchive style={{ marginRight: 8 }} />} />,
    key: 'archived',
  },
  {
    tab: <TabWithIcon text="Trash" icon={<IoTrashBinOutline style={{ marginRight: 8 }} />} />,
    key: 'trash',
  },
];

export default tabList;
