// Initialize Cloud Firestore through Firebase
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

export const app = initializeApp({
  apiKey: 'AIzaSyCy1R_segoCgCxPLC-v5TQbW_V5o4QjOj0',
  authDomain: 'engagement-application.firebaseapp.com',
  projectId: 'engagement-application',
  storageBucket: 'engagement-application.appspot.com',
  messagingSenderId: '412420608880',
  appId: '1:412420608880:web:f96e20fd8cd70f418875b5',
  measurementId: 'G-1032673B80',
});

export const db = getFirestore();

export default getFirestore();
