/* eslint-disable no-unused-vars */
import {
  PlusOutlined,
  SendOutlined,
  DeleteOutlined,
  DownSquareOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router';
import ProTable from '@ant-design/pro-table';
import { PageContainer } from '@ant-design/pro-layout';
import { Button, Alert, Space, notification, message } from 'antd';
import { useDispatch, useSelector, batch } from 'react-redux';
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { collection, doc, query, updateDoc, where, onSnapshot } from '@firebase/firestore';

import {
  setLeads,
  editLeads,
  setLoading,
  deleteLeads,
  clearSearch,
  updateLeadsMap,
  setImportError,
} from '@pages/leads/slice';
import { db } from '@services/firebase';
import useTabKey from '@hooks/useTabKey';
import { bulkUpdate } from '@utils/firebase';
import column from '@pages/leads/config/column';
import tabList from '@pages/leads/config/tabList';
import EditLead from '@pages/leads/components/EditLead';
import ImportLead from '@pages/leads/components/ImportLeads';
import expandedRowRender from '@pages/leads/components/StepsTableRow';

const Lead = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ref = useRef();
  const [newLead, setNewLead] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isDoingBulkOperation, setIsDoingBulkOperation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [leadEditting, setLeadEditting] = useState(null);
  const [openEditLead, setOpenEditLead] = useState(false);
  const [leadString, setLeadString] = useState('');
  const [userOptions, setUserOptions] = useState([]);
  const activeTab = useTabKey(
    useCallback(() => {
      setSelectedRowKeys([]);
    }, [])
  );

  const bulkActionEnabled = useMemo(() => selectedRowKeys.length === 0, [selectedRowKeys]);
  const [scrollProps, setScrollProps] = useState({
    scroll: { y: '60vh', x: '120vw' },
  });
  const { leads, searching, refetch, importError, loading } = useSelector((state) => state.leads);

  const searchObject = useMemo(() => {
    const pairs = history.location.search.slice(1).split('&');
    const result = {};
    pairs.forEach((pair) => {
      pair = pair.split('=');
      if (pair.length) {
        result[pair[0]] = decodeURIComponent(pair[1] || '');
        result[pair[0]] = decodeURIComponent(pair[1]?.replace(/\+/g, '%20') || '');
      }
    });
    return JSON.parse(JSON.stringify(result));
  }, [history.location.search]);
  const filteredUser = useMemo(() => {
    let isEquivalent = false;

    const filterClientData = leads.filter((clientData) => {
      let searchParams = Object.keys(searchObject);
      searchParams = searchParams.filter((item) => item !== 'type');

      for (let i = 0; i < searchParams.length; i += 1) {
        isEquivalent = false;
        const pattern = `${searchObject[searchParams[i]]}`.toLowerCase();
        const regex = new RegExp(pattern);
        if (searchParams[i] === 'user_id') {
          if (regex.test(clientData.user_id.toLowerCase())) {
            isEquivalent = true;
            break;
          }
        } else if (searchParams[i] === 'full_name') {
          if (regex.test((clientData.first_name + clientData.last_name).toLowerCase())) {
            isEquivalent = true;
            break;
          }
        } else if (regex.test(`${clientData[searchParams[i]]}`.toLowerCase())) {
          isEquivalent = true;
          break;
        }
      }
      if (isEquivalent && leadString) {
        const searchData = JSON.stringify(clientData)
          .toLowerCase()
          .includes(leadString.toLowerCase());
        if (searchData) {
          isEquivalent = true;
        } else {
          isEquivalent = false;
        }
      }
      if (!searchParams.length) {
        isEquivalent = true;
      }
      return isEquivalent;
    });
    return filterClientData;
  }, [searchObject, leads, leadString]);

  const showBulkOperation = searching ? filteredUser.length > 0 : leads.length > 0;
  const handleBulk = async (updateKey, type) => {
    try {
      setIsDoingBulkOperation(type);
      const conditions = {
        Archived: ['archived', 'Unarchive'],
        Published: ['published', 'Unpublished'],
        Deleted: ['trash', 'Restored'],
      };
      await bulkUpdate({
        searchValues: selectedRowKeys,
        searchIn: 'leads',
        searchField: 'key',
        replaceWith: { [updateKey]: conditions[type][0] !== activeTab },
        operationType: [conditions[type][0]].includes(activeTab) ? conditions[type][1] : type,
      });
      if (['trash', 'archived', 'published'].includes(activeTab) || type === 'Deleted') {
        batch(() => {
          selectedRowKeys.forEach((key) => dispatch(deleteLeads(key)));
        });
      } else {
        batch(() => {
          selectedRowKeys.forEach((key) =>
            dispatch(
              editLeads({ key, [updateKey]: [conditions[type][0], 'all'].includes(activeTab) })
            )
          );
        });
      }
    } catch (error) {
      notification.error({
        message: 'Operation Failed',
        description: 'Unable to perform bulk operation',
      });
    } finally {
      setIsDoingBulkOperation(false);
      setSelectedRowKeys([]);
    }
  };

  useEffect(() => {
    if (ref.current.setFieldsValue) {
      ref.current.setFieldsValue({
        ...searchObject,
      });
    }
  }, [searchObject, ref]);

  const toggleEditModal = (show, lead, isNewLead = false) => {
    setOpenEditLead(show);
    setLeadEditting(lead);
    setNewLead(isNewLead);
  };

  const onPressPublish = async (lead) => {
    await updateDoc(doc(db, 'leads', lead.key), {
      ...lead,
      is_published: !lead.is_published,
      updated_at: new Date().getTime(),
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const changeTab = (key) => {
    history.push(`${history.location.pathname}?type=${key}`);
    ref.current.resetFields();
  };

  const getLeads = useCallback(() => {
    dispatch(setLoading(true));
    let q;
    if (activeTab === 'published') {
      q = query(
        collection(db, 'leads'),
        where('is_deleted', '==', false),
        where('is_published', '==', true)
      );
    } else if (activeTab === 'archived') {
      q = query(
        collection(db, 'leads'),
        where('is_deleted', '==', false),
        where('is_archive', '==', true)
      );
    } else if (activeTab === 'trash') {
      q = query(collection(db, 'leads'), where('is_deleted', '==', true));
    } else {
      q = query(collection(db, 'leads'), where('is_deleted', '==', false));
    }
    return onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.docs && querySnapshot.docs.length) {
        const dataNew = querySnapshot.docs.map((docSnapshot) => ({
          ...docSnapshot.data(),
          key: docSnapshot.id,
        }));
        dispatch(setLeads(dataNew));
        dispatch(updateLeadsMap());
        setIsLoading(false);
      } else {
        dispatch(setLeads([]));
      }
    });
  }, [activeTab, dispatch]);

  useEffect(() => {
    const unsubscribe = getLeads();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [getLeads]);

  useEffect(() => {
    if (searching) {
      setScrollProps({
        scroll: filteredUser.length ? { y: '60vh', x: '120vw' } : 'auto',
      });
    } else {
      setScrollProps({
        scroll: leads.length ? { y: '60vh', x: '120vw' } : 'auto',
      });
    }
  }, [filteredUser.length, leads.length, history, searching]);

  return (
    <PageContainer
      tabList={tabList}
      onTabChange={changeTab}
      tabActiveKey={activeTab}
      tabBarExtraContent={
        <Button key="1" type="primary" onClick={() => setShowModal(true)}>
          Import Leads
        </Button>
      }
    >
      <EditLead
        show={openEditLead}
        lead={leadEditting}
        onCancel={toggleEditModal}
        newLead={newLead}
        userOptions={userOptions}
        setUserOptions={(data) => setUserOptions(data)}
      />
      <ImportLead modalProps={{ showModal, setShowModal }} />
      {importError && (
        <Alert
          message={importError}
          type="warning"
          closable
          className="mb-4"
          onClose={() => dispatch(setImportError(false))}
        />
      )}
      <ProTable
        formRef={ref}
        {...scrollProps}
        cardBordered
        headerTitle="Leads"
        defaultSize="small"
        options={{ reload: false, density: false }}
        rowKey={(record) => record.key}
        columns={column(onPressPublish, toggleEditModal, activeTab)}
        dataSource={filteredUser}
        onSubmit={(c) => {
          if (!isEmpty(c)) {
            const searchParams = new URLSearchParams('');
            if (searchObject?.type) {
              searchParams.set('type', searchObject?.type);
            }
            Object.keys(c).forEach((item) => {
              if (c[item]) {
                searchParams.set(item, c[item]);
              }
            });
            history.replace({
              pathname: history.location.pathname,
              search: searchParams.toString(),
            });
          } else {
            message.info('Please provide value to query');
          }
        }}
        onReset={() => {
          const searchParams = new URLSearchParams('');
          if (searchObject?.type) {
            searchParams.set('type', searchObject?.type);
          }
          history.replace({
            pathname: history.location.pathname,
            search: searchParams.toString(),
          });
          ref.current.resetFields();
        }}
        loading={loading}
        pagination={{
          total: filteredUser.length,
          showLessItems: true,
          responsive: true,
          pageSize: 10,
        }}
        search={{
          layout: 'vertical',
        }}
        expandable={{
          expandedRowRender,
        }}
        expandIconColumnIndex={1}
        toolbar={{
          search: {
            allowClear: true,
            placeholder: 'Search',
            onSearch: (val) => {
              setLeadString(val);
            },
            onChange: (event) => {
              if (!event.target.value) dispatch(clearSearch());
            },
          },
        }}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => toggleEditModal(true, {}, true)}
          >
            New
          </Button>,
        ]}
        rowSelection={rowSelection}
        footer={() =>
          showBulkOperation ? (
            <Space className="bulk-action-container">
              {activeTab !== 'trash' && (
                <>
                  <Button
                    loading={['Unpublished', 'Published'].includes(isDoingBulkOperation)}
                    disabled={bulkActionEnabled}
                    icon={<SendOutlined />}
                    onClick={() => handleBulk('is_published', 'Published')}
                    type="primary"
                  >
                    {activeTab === 'published' ? 'Unpublish' : 'Publish'}
                  </Button>

                  <Button
                    disabled={bulkActionEnabled}
                    icon={<DownSquareOutlined />}
                    loading={['Archived', 'Unarchive'].includes(isDoingBulkOperation)}
                    onClick={() => handleBulk('is_archive', 'Archived')}
                    className={!bulkActionEnabled && 'warning-btn'}
                  >
                    {activeTab === 'archived' ? 'Unarchive' : 'Archive'}
                  </Button>
                </>
              )}
              <Button
                danger
                type="primary"
                icon={activeTab !== 'trash' ? <DeleteOutlined /> : <RetweetOutlined />}
                disabled={bulkActionEnabled}
                loading={['Restored', 'Deleted'].includes(isDoingBulkOperation)}
                onClick={() => handleBulk('is_deleted', 'Deleted')}
              >
                {activeTab === 'trash' ? 'Restore' : 'Trash'}
              </Button>
            </Space>
          ) : null
        }
      />
    </PageContainer>
  );
};

export default Lead;
