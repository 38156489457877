import React from 'react';
import './index.less';

function ProgressBar({ loading }) {
  return (
    <div className="progress" style={{ width: '100%', visibility: loading ? 'visible' : 'hidden' }}>
      <div
        className={`progress-bar ${loading && `progress-bar-animated`}`}
        style={{ width: '100%', visibility: loading ? 'visible' : 'hidden' }}
        role="progressbar"
        label="Progress"
      />
    </div>
  );
}

export default ProgressBar;
