import React from 'react';
import { Result, notification } from 'antd';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  //   componentDidCatch(error, _errorInfo) {
  componentDidCatch(error) {
    notification.error({
      message: 'An error occurred',
      description: error.message,
      placement: 'bottomRight',
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Result
          status="error"
          title="Fatal Error Occurred"
          subTitle="Some unknown fatal error occurred, can't recover from this, try reloading application again."
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
