import React from 'react';
import { Tooltip, Button, message, Popconfirm } from 'antd';
import { deleteDoc, doc, updateDoc } from '@firebase/firestore';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';

import store from '@store';
import { db } from '@services/firebase';
import { deleteClient } from '@pages/users/slice';

/**
 * @description This function generates and returns ProTable Column Config Object
 * @param {Function} openModal
 * @returns ProTable columns Config
 */
const columns = (openModal, tab) => {
  const { dispatch } = store;
  /**
   * @description Deletes the template from fire store
   * @param {String} key
   */
  const handleDelete = async (record, temp = true) => {
    const { id } = record;
    try {
      const userRef = doc(db, 'users', id);
      if (temp) {
        await updateDoc(userRef, { ...record, isDeleted: true });
      } else {
        await deleteDoc(userRef);
      }
      dispatch(deleteClient(id));
      message.success('User Deleted');
    } catch (error) {
      message.error('Cant delete User');
    }
  };

  /**
   * @description Handle Restore the lead from fire store
   * @param {String} key
   */
  const handleRestore = async (record) => {
    const { id } = record;

    try {
      const leadRef = doc(db, 'users', id);
      await updateDoc(leadRef, { ...record, isDeleted: false });
      dispatch(deleteClient(id));
      message.success('User Restored');
    } catch (error) {
      message.error('Cant restore user');
    }
  };

  const colConfig = [
    {
      title: 'User ID',
      dataIndex: 'id',
      key: 'userId',
      width: 300,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Edit',
      key: 'edit',
      valueType: 'option',
      hideInSearch: true,
      fixed: 'right',
      width: 80,
      align: 'center',
      render: (_, record) => (
        <Tooltip title="Edit this user">
          <Button
            shape="circle"
            size="small"
            icon={<EditOutlined />}
            onClick={() => openModal(true, record)}
          />
        </Tooltip>
      ),
    },
    {
      title: 'Delete',
      valueType: 'option',
      key: 'delete',
      fixed: 'right',
      hideInSearch: true,
      width: 80,
      align: 'center',
      render: (text, record) => (
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleDelete(record)}
        >
          <Button danger shape="circle" size="small" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
    {
      title: 'Restore',
      valueType: 'option',
      key: 'restore',
      fixed: 'right',
      hideInSearch: true,
      width: 80,
      align: 'center',
      render: (text, record) => [
        <Tooltip title="Restore this user">
          <Button
            type="primary"
            shape="circle"
            icon={<RetweetOutlined />}
            onClick={() => handleRestore(record)}
          />
        </Tooltip>,
      ],
    },
    {
      title: 'Delete',
      valueType: 'option',
      key: 'deleteFinally',
      fixed: 'right',
      hideInSearch: true,
      width: 80,
      align: 'center',
      render: (text, record) => [
        <Tooltip placement="left" title="Delete this user permanently">
          <Button
            danger
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record, false)}
          />
        </Tooltip>,
      ],
    },
  ];

  return tab === 'trash'
    ? colConfig.filter((c) => !['edit', 'delete'].includes(c.key))
    : colConfig.filter((c) => !['restore', 'deleteFinally'].includes(c.key));
};

export default columns;
