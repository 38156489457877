import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { SaveOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { Alert, Button, Input, Drawer, Form, notification, message } from 'antd';
import { addDoc, collection, doc, setDoc, updateDoc, where } from '@firebase/firestore';

import { db } from '@services/firebase';
import Loading from '@components/Loading';
import useFillForm from '@hooks/useFillForm';
import { createTemplateSchema } from '@pages/template/utils';
import useResetDrawerScroll from '@hooks/useResetDrawerScroll';
import { getCollectionData, htmlToDraftBlocks } from '@utils/utils';
import { addNewTemplate, editTemplate } from '@pages/template/slice';

import '@editor/react-draft-wysiwyg.css';
import '@pages/template/components/AddTemplate/style.less';

const AddTemplate = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { show, template, handleClose } = props;
  useFillForm(template, form);
  const drawerBody = useResetDrawerScroll(show);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const setStates = (overrides = {}, defaults = { loading: isLoading, error: errorMessage }) => {
    const { loading, error } = { ...defaults, ...overrides };
    setIsLoading(loading);
    setErrorMessage(error);
  };

  const onCompleteOperation = (type, data) => {
    form.resetFields();
    handleClose(false, {});
    setStates({ loading: false, error: '' });
    notification.success({
      description: `Template ${type === 'add' ? 'added' : 'updated'} successfully`,
    });
    return type === 'add' ? dispatch(addNewTemplate(data)) : dispatch(editTemplate(data));
  };

  const saveTemplateData = async (values) => {
    try {
      let data = createTemplateSchema(values);
      const type = !isEmpty(template) ? 'update' : 'add';
      const getTemplate = (id) => doc(db, 'templates', `${id}`);
      if (type === 'update') {
        data = { ...data, templateId: template.templateId };
        await setDoc(getTemplate(data.templateId), data);
        onCompleteOperation(type, data);
      } else {
        const docRef = await addDoc(collection(db, 'templates'), data);
        await updateDoc(getTemplate(docRef.id), { templateId: docRef.id });
        data.templateId = docRef.id;
        onCompleteOperation(type, data);
      }
    } catch (error) {
      message.error('Error saving template');
    }
  };

  const onFinish = async (values) => {
    setStates({ loading: true, error: '' });
    saveTemplateData(values);
  };

  useEffect(() => {
    if (!isEmpty(template)) {
      setEditorState(htmlToDraftBlocks(template.description));
    }
    if (!show) setEditorState(EditorState.createEmpty());
  }, [show, template]);

  return (
    <Drawer
      centered
      visible={show}
      onClose={() => {
        form.resetFields();
        handleClose(false, {});
        setErrorMessage('');
      }}
      width={600}
      title={isEmpty(template) ? 'Add New Template' : 'Edit Selected Template'}
    >
      <Loading loading={isLoading}>
        <div className="custom-drawer-body" ref={drawerBody}>
          <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
            {errorMessage ? <Alert message={errorMessage} type="error" /> : null}
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Default Step"
              name="default_step"
              initialValue={null}
              rules={[
                () => ({
                  async validator(_rule, value) {
                    const doesItExists = await getCollectionData('templates', [
                      where('default_step', '==', value),
                    ]);
                    if (doesItExists.length === 0) {
                      return Promise.resolve();
                    }
                    if (doesItExists[0].templateId === template.templateId) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('This step value is already assigned to another template')
                    );
                  },
                }),
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please enter description!',
                },
              ]}
            >
              <Editor
                editorState={editorState}
                editorClassName="editor-class"
                onEditorStateChange={setEditorState}
                onChange={() =>
                  form.setFieldsValue({
                    description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                  })
                }
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
                style={{ minWidth: 100 }}
              >
                {isEmpty(template) ? 'Save Template' : 'Update Template'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Loading>
    </Drawer>
  );
};

export default AddTemplate;
