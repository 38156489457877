import { useHistory } from 'react-router';
import ProTable from '@ant-design/pro-table';
import { PageContainer } from '@ant-design/pro-layout';
import { Button, message, notification, Space, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import { collection, query, where, onSnapshot } from '@firebase/firestore';
import { setTemplate, setEditOrAddTemplate, setLoading } from '@pages/template/slice';
import isEmpty from 'lodash/isEmpty';
import { db } from '@services/firebase';
import useTabKey from '@hooks/useTabKey';
import { bulkUpdate } from '@utils/firebase';
import columns from '@pages/template/config/column';
import tabList from '@pages/template/config/tabList';
import AddTemplate from '@pages/template/components/AddTemplate';
import expandedRowRender from '@pages/template/components/RenderDescription';

const Template = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isDoingBulkOperation, setIsDoingBulkOperation] = useState(false);
  const [searchString, setSearchString] = useState('');

  const bulkActionEnabled = useMemo(() => selectedRowKeys.length === 0, [selectedRowKeys]);
  const { template, filterTemplate, searching, loading, editOrAddTemplate } = useSelector(
    (state) => state.template
  );
  const ref = useRef();
  const { selectedTemplate, openAddTemplate } = editOrAddTemplate;
  const activeTabKey = useTabKey(
    useCallback(() => {
      setSelectedRowKeys([]);
    }, [])
  );
  const searchObject = useMemo(() => {
    const pairs = history.location.search.slice(1).split('&');
    const result = {};
    pairs.forEach((pair) => {
      pair = pair.split('=');
      if (pair.length) {
        result[pair[0]] = decodeURIComponent(pair[1] || '');
        result[pair[0]] = decodeURIComponent(pair[1]?.replace(/\+/g, '%20') || '');
      }
    });
    return JSON.parse(JSON.stringify(result));
  }, [history.location.search]);

  const filteredTemplate = useMemo(() => {
    let isEquivalent = false;
    const filteredTemplateData = template.filter((templateData) => {
      let searchParams = Object.keys(searchObject);
      searchParams = searchParams.filter((item) => item !== 'type');

      for (let i = 0; i < searchParams.length; i += 1) {
        isEquivalent = false;
        const pattern = `${searchObject[searchParams[i]]}`.toLowerCase();
        const regex = new RegExp(pattern);
        if (searchParams[i] === 'userId') {
          if (regex.test(templateData.id.toLowerCase())) {
            isEquivalent = true;
            break;
          }
        } else if (regex.test(`${templateData[searchParams[i]]}`.toLowerCase())) {
          isEquivalent = true;
          break;
        }
      }
      if (isEquivalent && searchString) {
        const searchData = JSON.stringify(templateData)
          .toLowerCase()
          .includes(searchString.toLowerCase());
        if (searchData) {
          isEquivalent = true;
        } else {
          isEquivalent = false;
        }
      }
      if (!searchParams.length) {
        isEquivalent = true;
      }
      return isEquivalent;
    });
    return filteredTemplateData;
  }, [searchObject, template, searchString]);

  const showBulkOperation = searching ? filterTemplate.length > 0 : template.length > 0;

  const toggleEditTemplate = (show, record = {}) => {
    dispatch(
      setEditOrAddTemplate({
        selectedTemplate: record,
        openAddTemplate: show,
      })
    );
  };

  const handleBulk = async (updateKey, type) => {
    try {
      setIsDoingBulkOperation(type);
      await bulkUpdate({
        searchValues: selectedRowKeys,
        searchIn: 'templates',
        searchField: 'templateId',
        replaceWith: { [updateKey]: activeTabKey !== 'trash' },
        operationType: type,
      });
      // batch(() => {
      //   selectedRowKeys.forEach((key) => dispatch(deleteTemplate(key)));
      // });
    } catch (error) {
      notification.error({ message: 'Error occurred', description: 'Operation failed try again' });
    } finally {
      setIsDoingBulkOperation(false);
      setSelectedRowKeys([]);
    }
  };

  useEffect(() => {
    if (ref.current?.setFieldsValue) {
      ref.current.setFieldsValue({
        ...searchObject,
      });
    }
  }, [searchObject, ref]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const changeTab = (key) => {
    history.push(`${history.location.pathname}?type=${key}`);
    ref.current.resetFields();
  };

  const getTemplates = useCallback(() => {
    dispatch(setLoading(true));
    let q = query(collection(db, 'templates'));
    if (activeTabKey === 'all') {
      q = query(collection(db, 'templates'), where('is_deleted', '==', false));
    } else if (activeTabKey === 'trash') {
      q = query(collection(db, 'templates'), where('is_deleted', '==', true));
    }

    return onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.docs && querySnapshot.docs.length) {
        const dataNew = querySnapshot.docs.map((docSnapshot) => ({
          ...docSnapshot.data(),
          key: docSnapshot.id,
        }));
        dispatch(setTemplate(dataNew));
      } else {
        dispatch(setTemplate([]));
      }
    });
  }, [activeTabKey, dispatch]);

  useEffect(() => {
    let unsubscribe = null;
    unsubscribe = getTemplates();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [getTemplates]);

  return (
    <PageContainer
      tabList={tabList}
      onTabChange={changeTab}
      tabActiveKey={activeTabKey}
      tabBarExtraContent={
        <Button key="1" type="primary" onClick={() => toggleEditTemplate(true, {})}>
          Add Template
        </Button>
      }
    >
      <AddTemplate
        template={selectedTemplate}
        handleClose={toggleEditTemplate}
        show={openAddTemplate}
      />

      <Spin spinning={loading}>
        <ProTable
          formRef={ref}
          expandIconColumnIndex={1}
          cardBordered
          defaultSize="small"
          scroll={{ y: '60vh' }}
          headerTitle="Templates"
          options={{ reload: false, density: false }}
          search={{ layout: 'vertical' }}
          columns={columns(toggleEditTemplate, activeTabKey)}
          dataSource={filteredTemplate}
          rowKey={(record) => record.key}
          onSubmit={(c) => {
            if (!isEmpty(c)) {
              const searchParams = new URLSearchParams('');
              if (searchObject?.type) {
                searchParams.set('type', searchObject?.type);
              }
              Object.keys(c).forEach((item) => {
                if (c[item]) {
                  searchParams.set(item, c[item]);
                }
              });
              history.replace({
                pathname: history.location.pathname,
                search: searchParams.toString(),
              });
            } else {
              message.info('Please provide value to query');
            }
          }}
          onReset={() => {
            const searchParams = new URLSearchParams('');
            if (searchObject?.type) {
              searchParams.set('type', searchObject?.type);
            }
            history.replace({
              pathname: history.location.pathname,
              search: searchParams.toString(),
            });
            ref.current.resetFields();
          }}
          pagination={{
            total: filteredTemplate.length,
            showLessItems: true,
            responsive: true,
            pageSize: 10,
          }}
          expandable={{
            expandedRowRender,
          }}
          rowSelection={rowSelection}
          toolbar={{
            search: {
              allowClear: true,
              placeholder: 'Search',
              onSearch: (val) => {
                setSearchString(val);
              },
            },
          }}
          footer={() =>
            showBulkOperation ? (
              <Space className="bulk-action-container">
                <Button
                  danger
                  type="primary"
                  icon={activeTabKey !== 'trash' ? <DeleteOutlined /> : <RetweetOutlined />}
                  disabled={bulkActionEnabled}
                  loading={isDoingBulkOperation === 'Deleted'}
                  onClick={() =>
                    handleBulk('is_deleted', activeTabKey !== 'trash' ? 'Deleted' : 'Restored')
                  }
                >
                  {activeTabKey !== 'trash' ? 'Delete' : 'Restore'}
                </Button>
              </Space>
            ) : null
          }
        />
      </Spin>
    </PageContainer>
  );
};

export default Template;
