import { userSchema as validator } from '@pages/users/validation';

/**
 * @description validates and return user object
 * @param {string} name required
 * @param {Object} user required
 * @returns user object
 */
export const createUserSchema = (name, user) => (errorMessage) => {
  const userSchema = {
    name,
    id: user.uid,
    email: user.email,
    createdAt: new Date().getTime(),
    isAdmin: false,
    isDeleted: false,
  };

  const validate = validator.validate(userSchema);

  if (validate.error) {
    throw Error(errorMessage ?? validate.error.message);
  }
  return userSchema;
};

/**
 * @description return new user object while updating it with the new values provided as
 * @param {Object} selectedUser required
 * @param {String} name required
 * @returns user object
 */
export const createUpdateUserSchema = (selectedUser, name) => ({
  ...selectedUser,
  name,
  updatedAt: new Date().getTime(),
});

/**
 *
 * @param {String} errorCode
 * @param {({error?: string, loading?: boolean, reset?: boolean}) => void} setStates
 */
export const handleError = (errorCode, setStates) => {
  setStates({ loading: false });
  if (errorCode === 'password-mismatch') {
    setStates({ error: "Passwords don't match!" });
  } else if (errorCode === 'auth/email-already-in-use') {
    setStates({ error: 'This email is already registered!' });
  } else {
    setStates({ error: 'Something went wrong! Please try again later' });
  }
};

export { createUserSchema as default };
